import React from 'react';
import TournamentsTrLoading from "./TournamentsTrLoading";
import {observer} from "mobx-react-lite";

const TournamentsTr = props => {
  const users = props?.data;

  return (
    <tr>
      <td><div className="td_content">{!props.loading ? (users && users[0] && users[0].login) ? users[0].login : '' : <TournamentsTrLoading /> }</div></td>
      <td><div className="td_content">{!props.loading ? (users && users[1] && users[1].login) ? users[1].login : '' : <TournamentsTrLoading /> }</div></td>
      <td><div className="td_content">{!props.loading ? (users && users[2] && users[2].login) ? users[2].login : '' : <TournamentsTrLoading /> }</div></td>
    </tr>
  );
};

export default observer(TournamentsTr);