import React from 'react';
import {observer} from "mobx-react-lite";

const WinnersTr = ({item, index}) => {
  return (
    <tr>
      <td>
        <div className="td_content">{index && index}</div>
      </td>
      <td>
        <div className="td_content">{item?.date}</div>
      </td>
      <td>
        <div className="td_content">{item?.login}</div>
      </td>
      <td>
        <div className="td_content">{item?.sale}</div>
      </td>
      <td>
        <div className="td_content">{item?.sum}</div>
      </td>
    </tr>
  );
};

export default observer(WinnersTr);