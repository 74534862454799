import api from "../http";

export default class PostService {
  static async getAll(url, page=1) {
    const response = await api.get(url, {
      params: {
        page: page
      }
    });
    return response
  }

  // static async getById(id) {
  //   const response = await api.get(`https://jsonplaceholder.typicode.com/posts/${id}`);
  //   return response
  // }
}