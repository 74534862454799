import React, { useRef, useEffect, useState } from 'react';
import {observer} from "mobx-react-lite";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
import SliderBox from "./SliderBox";
import {useFetching} from "../../hooks/useFetching";
import {API_VIDEO_SLIDER} from "../../utils/consts";
import ApiVideoSlider from "../../services/VideoSlider";

import "swiper/components/navigation/navigation.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import 'swiper/swiper-bundle.min.css';

const Slider = () => {
  const swiperRef = useRef(null);
  SwiperCore.use([Navigation, Pagination, EffectFade]);

  const [playVideo, setPlayVideo] = useState(false);
  const [dataVideo, setDataVideo] = useState(
    // [
    //   {
    //     "id": 60,
    //     "name": "test123213",
    //     "title": "3455345345",
    //     "preview_image": "/media/media/VideoPreview/56711-kot_suhaya_trava_seamskij.jpg",
    //     "url": {
    //       "category_slug": "chempionska-tusovka",
    //       "video_slug": "test123213"
    //     },
    //     "video_url": "https://www.youtube.com/embed/7UNIwBtYEDE",
    //     "video_url_iFrame": "https://www.youtube.com/embed/7UNIwBtYEDE"
    //   },
    //   {
    //     "id": 59,
    //     "name": "test_uk",
    //     "title": "test_uk",
    //     "preview_image": "/media/media/VideoPreview/1.jpg",
    //     "url": {
    //       "category_slug": "chempionska-tusovka",
    //       "video_slug": "test-uk"
    //     },
    //     "video_url": "https://www.youtube.com/embed/7UNIwBtYEDE",
    //     "video_url_iFrame": "https://www.youtube.com/embed/7UNIwBtYEDE"
    //   },
    //   {
    //     "id": 57,
    //     "name": "Первый стрим",
    //     "title": "Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться.",
    //     "preview_image": "/media/media/VideoPreview/5adf1b97742a65d0a3c98299c545570b_BnP064W.jpg",
    //     "url": {
    //       "category_slug": "strimery",
    //       "video_slug": "pervyi-strim"
    //     },
    //     "video_url": "https://www.youtube.com/embed/7UNIwBtYEDE",
    //     "video_url_iFrame": ""
    //   },
    //   {
    //     "id": 53,
    //     "name": "Час с Чемпионом",
    //     "title": "Lorem Ipsum - это текст-\"рыба\", часто используемый в печати и вэб-дизайне.",
    //     "preview_image": "/media/media/VideoPreview/pic-00892_2M4acxS.jpg",
    //     "url": {
    //       "category_slug": "chempionskii-chas",
    //       "video_slug": "chas-s-chempionom"
    //     },
    //     "video_url": "https://www.youtube.com/embed/7UNIwBtYEDE",
    //     "video_url_iFrame": ""
    //   },
    //   {
    //     "id": 50,
    //     "name": "Тусовка №1",
    //     "title": "Lorem Ipsum - это текст-\"рыба\", часто используемый в печати и вэб-дизайне.",
    //     "preview_image": "/media/media/VideoPreview/041-741x367_NrA4up8.jpg",
    //     "url": {
    //       "category_slug": "chempionskaia-tusovka",
    //       "video_slug": "tusovka-1"
    //     },
    //     "video_url": "https://www.youtube.com/embed/7UNIwBtYEDE",
    //     "video_url_iFrame": ""
    //   }
    // ]
  );

  const [FunctionApiVideoSlider, loadingFunctionApiVideoSlider, errorMessage, errorStatus, errorPage] = useFetching(async () => {
    const response = await ApiVideoSlider.getApiVideoSlider(API_VIDEO_SLIDER);
    setDataVideo(response.data?.slider_videos);
  });

  useEffect(() => {
    FunctionApiVideoSlider();
  }, []);

  return (
    <>
      <div className={`slider ${playVideo && 'play'}`}>
        <Swiper
          loop={true}
          spaceBetween={0}
          initialSlide={0}
          slidesPerView={1}
          autoHeight={true}
          allowTouchMove={false}
          className="swiper videoSwiper"
          ref={swiperRef}
          effect="fade"
          fadeEffect={{
            crossFade: true
          }}
          pagination={{
            clickable: false
          }}
        >
          {(loadingFunctionApiVideoSlider || errorPage || !dataVideo || dataVideo?.length === 0)
            ? <SwiperSlide><SliderBox dataVideo={{}} loadingFunctionApiVideoSlider={loadingFunctionApiVideoSlider} setPlayVideo={setPlayVideo} /></SwiperSlide>
            : dataVideo.map(item=><SwiperSlide key={item.id}><SliderBox dataVideo={item} loadingFunctionApiVideoSlider={loadingFunctionApiVideoSlider} setPlayVideo={setPlayVideo} /></SwiperSlide>)}
        </Swiper>
        {dataVideo && dataVideo.length > 1 && <div className="videoSwiper-button-prev" onClick={() => swiperRef.current.swiper.slidePrev()} />}
        {dataVideo && dataVideo.length > 1 && <div className="videoSwiper-button-next" onClick={() => swiperRef.current.swiper.slideNext()} />}
      </div>
    </>
  );
};

export default observer(Slider);