import React from 'react';
import {useTranslation} from "react-i18next";

import fb from '../../img/icons/new/fb.svg'
import tg from '../../img/icons/new/tg.svg'
import vb from '../../img/icons/new/vb.svg'
import {observer} from "mobx-react-lite";

const Share = ({url}) => {
  const {t} = useTranslation();

  return (
    <div className="share">
      <span>{t('Рассказать')}:</span>
      <div className="icons">
        <a href={`https://www.facebook.com/sharer/sharer?u=${url}`} className="icon"
           data-alt="fb">
          <img src={fb} alt="fb"/>
        </a>
        <a href={`https://t.me/share/url?url=${url}&text=`} className="icon" data-alt="tg">
          <img src={tg} alt="tg"/>
        </a>
        <a href={`viber://forward?text= ${url}`} className="icon" data-alt="vb">
          <img src={vb} alt="vb"/>
        </a>
      </div>
    </div>
  );
};

export default observer(Share);