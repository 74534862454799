import React, {useEffect, useState} from 'react';
import {API_WINNERS, INDEX_ROUTE, WINNERS_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import WinnersTr from "../components/Winners/WinnersTr";
import Loading from "../components/Loading";
import {useFetching} from "../hooks/useFetching";
import ApiWinners from "../services/Winners";
import Seo from "../components/Seo";
import {observer} from "mobx-react-lite";
import NoResult from "../components/NoResult";

import '../style/partials/_winners.scss'
import Breadcrumbs from "../components/Breadcrumbs";

const WinnersPage = () => {
  const {t} = useTranslation();
  const [dataWinners, setDataWinners] = useState();

  const [FunctionApiWinners, loadingFunctionApiWinners] = useFetching(async () => {
    const response = await ApiWinners.getApiWinners(API_WINNERS);
    setDataWinners(response.data?.winner_list);
  });

  useEffect(() => {
    FunctionApiWinners();
  }, []);

  return (
    <>
      <Seo page="winners" />
      <div className="winners container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5"/>
          <meta property="ratingValue" content="4.1"/>
          <meta property="ratingCount" content="1085"/>
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: WINNERS_ROUTE, name: 'Победители'}
        ]}/>
        <div className="container">
          <div className="main_products">
            <h1>{t('Победители')}</h1>
            {loadingFunctionApiWinners
              ? <Loading />
              : dataWinners?.length > 0
                ? <div className="table">
                    <OverlayScrollbarsComponent>
                        <div className="table_responsive">
                          <table>
                            <thead>
                            <tr>
                              <th>№</th>
                              <th>{t('Дата')}</th>
                              <th>{t('ID или логин')}</th>
                              <th>{t('Акция')}</th>
                              <th>{t('Сумма, грн')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataWinners.map((item, i) => <WinnersTr item={item} index={i+1} />)}
                            </tbody>
                          </table>
                        </div>
                    </OverlayScrollbarsComponent>
                  </div>
                : <NoResult />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(WinnersPage);