import React, { useState, useEffect } from 'react';
import {NavLink} from "react-router-dom";
import GenerateURL from "../../utils/generateURL";
import {API_VIDEO_CATEGORY, VIDEO_ROUTE} from "../../utils/consts";
import {useFetching} from "../../hooks/useFetching";
import ApiVideoSlider from "../../services/VideoSlider";
import {observer} from "mobx-react-lite";

const Category = () => {
  const [itemCategory, setItemCategory] = useState(
    [
      // {
      //   "id": 20,
      //   "name": "Чемпіонська тусовка",
      //   "slug": "chempionska-tusovka"
      // },
      // {
      //   "id": 21,
      //   "name": "Чемпіонська година",
      //   "slug": "chempionska-godina"
      // },
      // {
      //   "id": 22,
      //   "name": "Стрімери",
      //   "slug": "strimeri"
      // },
      // {
      //   "id": 23,
      //   "name": "Спецпроєкти",
      //   "slug": "spetsproiekti"
      // }
    ]
  );

  const [FunctionApiVideoCategory] = useFetching(async () => {
    const response = await ApiVideoSlider.getApiVideoSlider(API_VIDEO_CATEGORY);
    setItemCategory(response.data?.category_list);
  });

  useEffect(() => {
    FunctionApiVideoCategory();
  }, []);

  return (
    <>
      {itemCategory.length > 0 && <div className="category">
        {itemCategory.map(item =><NavLink key={item.id} to={GenerateURL(VIDEO_ROUTE+'/'+item.slug)}><span>{item.name}</span></NavLink>)}
      </div>}
    </>
  );
};

export default observer(Category);