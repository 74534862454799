import {default_language} from "./lang";
import PathToRegexp from "path-to-regexp";
import {ROUTE} from "./consts";

const generateLanguageURL = (url, language) => {
  const routeComponents = PathToRegexp(ROUTE).exec(url);
  if (routeComponents && routeComponents[2]) {
    if (routeComponents && routeComponents[1].length === 2) {
      if (default_language === language) {
        return `/${routeComponents[2]}`;
      } else {
        return `/${language}/${routeComponents[2]}`;
      }
    } else {
      if (default_language === language) {
        return routeComponents[0];
      } else {
        return `/${language+routeComponents[0]}`;
      }
    }
  } else if (routeComponents && routeComponents[1]) {
    if (routeComponents && routeComponents[1].length === 2) {
      if (default_language === language) {
        return '';
      } else {
        return `/${routeComponents && routeComponents[1]}/`;
      }
    } else {
      if (default_language === language) {
        return `/${routeComponents && routeComponents[1]}`;
      } else {
        return `/${routeComponents && language+'/'+routeComponents[1]}`;
      }
    }
  } else {
    if (routeComponents && routeComponents[1].length === 2) {
      if (default_language === language) {
        return '';
      } else {
        return language+'/'
      }
    } else {
      if (default_language === language) {
        return '';
      } else {
        return language+'/'
      }
    }
  }
};

export default generateLanguageURL;