import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from "i18next"
import {languages} from '../../utils/lang'
import generateLanguageURL from "../../utils/generateLanguageURL";
import {observer} from "mobx-react-lite";
import {LanguageContext} from "../../App";
import cookie from "js-cookie";

const Language = props => {
  const location = useLocation();
  const [languageContext, setLanguageContext] = useContext(LanguageContext);
  const currentLanguageCode = i18next.language || 'ru';

  const handleClick = e => {
    const code = e.target.dataset.lang;
    if (e.target.classList.contains('active')) {
      e.preventDefault();
    } else {
      if (e.target.href !== window.location.origin.toString()+location.pathname) {
        i18next.changeLanguage(code);
      }
      props.setMobileMenu(false);
      props.setMobileMenuFooter(false);
      document.body.classList.remove('fixed');
      document.body.classList.remove('footer');
      document.body.style.top = "0px";
      document.body.style.paddingRight = "0px";
      document.querySelector('#js-mobile_menu').classList.remove('footer_breadcrumbs');
      document.querySelector('#js-mobile_menu').classList.remove('footer');
      document.querySelector('#js-mobile_menu').classList.remove('active');
      document.querySelector('#js-mobile_menu').style.opacity = null;
      document.querySelector('#js-mobile_menu').style.height = null;
    }
  };


  return (
    <ul className="lang">
      {languages.map(({ code}) => {
        return <li key={code}>
          <Link
            to={Object.keys(languageContext).length === 0
              ? generateLanguageURL(location.pathname, code)
              : languageContext[code]
                ? generateLanguageURL(languageContext[code], code)
                : generateLanguageURL(languageContext[cookie.get('i18next')], cookie.get('i18next'))}
            className={code === currentLanguageCode ? 'active' : ''}
            onClick={handleClick}
            data-lang={code}
          >{code}</Link>
        </li>
      })}
    </ul>
  );
};

export default observer(Language);