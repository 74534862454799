import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import GenerateURL from "../../utils/generateURL";
import {VIDEO_ROUTE} from "../../utils/consts";

import ico_pause from '../../img/video/start-video-button.png'
import ico_close from '../../img/icons/close_menu.svg'
import ico_loading from '../../img/icons/loading.svg'

const SliderBox = ({dataVideo, loadingFunctionApiVideoSlider}) => {
  const { t } = useTranslation();
  const [pause, setPause] = useState(false);
  const iFrameRef = useRef(null);

  const playHandle = () => {
    const ampsd = iFrameRef.current.src.indexOf('?') !== -1 ? '&' : '?';
    iFrameRef.current.src = iFrameRef.current.src + ampsd + "autoplay=1";
    setPause(true);
  };

  return (
    <>
      {dataVideo && <div className="videoSwiper_box">
        <div className="content">
          <div className="content_video">
            <button
              className={`js-click_play_video pause ${!loadingFunctionApiVideoSlider && !(dataVideo?.video_url || dataVideo?.video_url_iFrame) && 'close'} ${!pause && 'active'}`}
              onClick={playHandle}>
              {loadingFunctionApiVideoSlider && <img src={ico_loading} alt={t('Загрузка')} title={t('Загрузка')} />}
              {!loadingFunctionApiVideoSlider && (dataVideo?.video_url || dataVideo?.video_url_iFrame) && <img src={ico_pause} alt={t('Играть')} title={t('Играть')} />}
              {!loadingFunctionApiVideoSlider && !(dataVideo?.video_url || dataVideo?.video_url_iFrame) && <img src={ico_close} alt={t('Недоступно')} title={t('Недоступно')} />}
            </button>
            {!pause && <div className="content_img">
              {dataVideo?.preview_image && <img src={dataVideo?.preview_image} alt="poster" />}
            </div>}
            <div className="content_iframe">
              {(dataVideo?.video_url || dataVideo?.video_url_iFrame) && <iframe
                ref={iFrameRef}
                width="560"
                height="315"
                src={dataVideo?.video_url || dataVideo?.video_url_iFrame}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />}
            </div>
          </div>
          <div className="content_text">
            <Link to={GenerateURL(`${VIDEO_ROUTE}/${dataVideo?.url?.category_slug}/${dataVideo?.url?.video_slug}`)}><h2>{dataVideo?.name}</h2>
            </Link>
            <p>{dataVideo?.title}</p>
          </div>
        </div>
      </div>}
    </>
  );
};

export default observer(SliderBox);