import React from 'react';
import {ACTIONS_ROUTE, ANNOUNCEMENT_ROUTE} from "../../utils/consts";
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import i18next from "i18next";
import GenerateURL from "../../utils/generateURL";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";

const AnnouncementsBox = ({item, href, logotype}) => {
  const { t } = useTranslation();

  const formatDate = (date) => {
    const options = {weekday: 'short', month: 'short', day: 'numeric'};
    const current = new Date(date).toLocaleDateString(i18next.language, options);
    return current[0].toUpperCase() + current.slice(1).replace('.', '')
  };

  return (
    <div className="box" id={item.id} itemScope itemType="http://schema.org/Article">
      {logotype && <div className="box_left">
        <img src={item?.logotype} alt="ico" />
      </div>}
      <div className="box_right">
        <div className="top">
          <meta itemProp="datePublished" content={item.start_date} />
          <link itemProp="url" href={GenerateURL(ANNOUNCEMENT_ROUTE+'#'+item.id)} />
          {href
            ? <h3><Link to={{pathname:GenerateURL(ANNOUNCEMENT_ROUTE), hash:`#${item.id}`}} itemProp="name">{item?.name}</Link></h3>
            : <h3 itemProp="name">{item?.name}</h3>}
          {item.time_format && <span className={`time ${(item?.time_format?.now || item?.time_format?.now_to) && 'current'}`}>
                    {item?.time_format?.now && `${t('Сейчас')}, `}
            {item?.time_format?.now_to && `${t('Сейчас')}, ${t('до')} `}
            {item?.time_format?.start_date && `${formatDate(item?.time_format?.start_date)} `}
            {item?.time_format?.start_time && `${item?.time_format?.start_time} `}
            {item?.time_format?.end_date && `${!item?.time_format?.now_to ? '-' : ''} ${formatDate(item?.time_format?.end_date)} `}
            {item?.time_format?.end_time && `${!(item?.time_format?.now_to || item?.time_format?.end_date) ? '-' : ''} ${item?.time_format?.end_time}`}
                  </span>}
        </div>
        <div className="bottom" itemProp="articleBody">
          {item?.text && parse(item?.text)}
          {item?.sale && <Link to={item.sale && GenerateURL(ACTIONS_ROUTE+'/'+item.sale)} className="sale">
            <span>{t('Подробнее про акцию')}</span>
          </Link>}
        </div>
      </div>
    </div>
  );
};

export default observer(AnnouncementsBox);