import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import i18next from "i18next";
import {default_language} from "../../utils/lang";

const Sidebar = ({items, page}) => {
  const {t} = useTranslation();

  return (
    <div className="side-bar">
      <h2>{t('Похожие Статьи')}</h2>
      <div className="boxing">
        {items && items.map(item=><div className="box">
          <div className="box_left">
            <img src={item?.preview_image} alt={`${item?.name} ChampionClub`}/>
          </div>
          <div className="box_right">
            <Link
              onClick={()=>i18next.changeLanguage(item.lang)}
              to={`${item?.lang === default_language ? `` : `/${item?.lang}`}${page}/${item?.slug}`}>
              <h2>{item?.name}</h2>
              <p>{item?.title}</p>
            </Link>
          </div>
        </div>)}
      </div>
    </div>
  );
};

export default observer(Sidebar);