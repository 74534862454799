import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
  ANNOUNCEMENT_ARCHIVE_ROUTE,
  ANNOUNCEMENT_ROUTE,
  API_INDEX_ANNOUNCEMENT,
  INDEX_ROUTE
} from "../utils/consts";
import {useTranslation} from "react-i18next";
import GenerateURL from "../utils/generateURL";
import Seo from "../components/Seo";
import {useFetching} from "../hooks/useFetching";
import ApiIndexAnnouncement from "../services/IndexAnnouncement";
import Loading from "../components/Loading";
import AnnouncementsBox from "../components/Announcements/AnnouncementsBox";

import ico_archive from '../img/icons/archive.svg'
import '../style/partials/_announcements.scss'
import {observer} from "mobx-react-lite";
import NoResult from "../components/NoResult";
import Breadcrumbs from "../components/Breadcrumbs";

const AnnouncementPage = () => {
  const { t } = useTranslation();
  const [dataIndexAnnouncement, setDataIndexAnnouncement] = useState();

  const [FunctionApiIndexAnnouncement, loadingFunctionApiIndexAnnouncement] = useFetching(async () => {
    const response = await ApiIndexAnnouncement.getApiIndexAnnouncement(API_INDEX_ANNOUNCEMENT, 10);
    setDataIndexAnnouncement(response.data?.announcement_list);
  });

  useEffect(() => {
    FunctionApiIndexAnnouncement();
  }, []);

  useEffect(()=>{
    if (window.location.hash && !loadingFunctionApiIndexAnnouncement && dataIndexAnnouncement?.length > 0) {
      window.location.href = window.location.hash
    }
  }, [loadingFunctionApiIndexAnnouncement, dataIndexAnnouncement]);

  return (
    <>
      <Seo page="announcement" />
      <div className="announcements container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content="4.9" />
          <meta property="ratingCount" content="1248" />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: ANNOUNCEMENT_ROUTE, name: 'Анонсы событий'}
        ]}/>
        <div className="container">
          <div className="event_announcements">
            <h1>{t('Анонсы событий')}</h1>
            <div className="boxing">
              {loadingFunctionApiIndexAnnouncement
                ? <Loading />
                : dataIndexAnnouncement?.length > 0
                  ? <div className="boxing">{dataIndexAnnouncement.map(item=><AnnouncementsBox item={item} href={false} logotype={false} />)}</div>
                  : <NoResult />
              }
              <Link to={GenerateURL(ANNOUNCEMENT_ARCHIVE_ROUTE)} className="archive">
                <img src={ico_archive} alt="ico_archive" />
                {t('Архив анонсов')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AnnouncementPage);