import React from 'react';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";

const ErrorPage = props => {
  const { t } = useTranslation();
  const status = props.status || 404;
  const currentError = {
    403: {
      title: "Нет Доступа",
      description: "Извините, страница, которую вы искали, больше не доступна."
    },
    404: {
      title: "Страница не найдена",
      description: "Извините, страница, которую вы искали, больше не доступна."
    },
    500: {
      title: "Внутренняя Ошибка Сервера",
      description: "Извините, страница, которую вы искали, больше не доступна."
    }
  };

  return currentError[status] && (
    <div className="fhf background">
      <div className="fhf_content">
        <div className="title_block">
          <h1>{t("Ошибка")}</h1>
          {status && <h2>{status}</h2>}
          {status && <h3>{t(currentError[status].title)}</h3>}
          <p>{t(currentError[status].description || "Простите, за временные трудности! Попробуйте чуть позже.")}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(ErrorPage);