import i18next from "i18next";
import { useLocation } from 'react-router-dom'
import PathToRegexp from "path-to-regexp";
import {ROUTE} from "./consts";

const GenerateURL = (url) => {
  const location = useLocation();
  const routeComponents = PathToRegexp(ROUTE).exec(location.pathname);
  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    if (routeComponents && routeComponents[1].length === 2) {
      return `/${i18next.language}${url}`
    } else {
      return url;
    }
  } else if (routeComponents && routeComponents[1]) {
    subPaths = routeComponents[1].split("/");
    subPaths = subPaths[0].length === 2 ? routeComponents[1].split("/") : null;
    return subPaths ? `/${i18next.language}${url}` : url;
  } else {
    return url;
  }
};


export default GenerateURL;