import React, { useState, useRef, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import videojs from "video.js";
import {useFetching} from "../../hooks/useFetching";
import {API_INDEX_VIDEO} from "../../utils/consts";
import ApiIndexVideo from "../../services/IndexVideo";
import {observer} from "mobx-react-lite";

import 'videojs-youtube'
import "video.js/dist/video-js.css";
import img_poster from '../../img/poster.png'
import ico_pause from '../../img/icons/play-player.svg'
import ico_close from '../../img/icons/close_menu.svg'
import ico_loading from '../../img/icons/loading.svg'

const Video = () => {
  const { t } = useTranslation();
  const [pause, setPause] = useState(false);
  const [player, setPlayer] = useState();
  const videoRef = useRef(null);
  const iFrameRef = useRef(null);
  const [dataVideo, setDataVideo] = useState();

  const [FunctionApiIndexVideo, loadingFunctionApiIndexVideo] = useFetching(async () => {
    const response = await ApiIndexVideo.getApiIndexVideo(API_INDEX_VIDEO);
    setDataVideo(response.data);
  });

  useEffect(() => {
    FunctionApiIndexVideo();
    // setDataVideo(
    //   {
    //     // "stream_url": "https://champion-club.cdn-02.cosmonova.net.ua/mobile-app/main/Champion-club/master.m3u8",
    //     "stream_url_iFrame": "https://www.youtube.com/embed/xjS6SftYQaQ",
    //     // "playing_now": "“Somewhere in My Memory”",
    //     // "playing_next": "“Somewhere in My Memory”",
    //     "status": true
    //   }
    // )
  }, []);

  useEffect(()=>{
    if (dataVideo?.status) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const videoJs = videojs(videoElement, {
        controls: true,
        bigPlayButton: false,
        poster: img_poster,
        sources: [{
          src: dataVideo?.stream_url ? dataVideo?.stream_url : dataVideo?.stream_url_iFrame,
          type: dataVideo?.stream_url ? 'application/x-mpegURL' : 'video/youtube',
          // src: 'https://champion-club.cdn-02.cosmonova.net.ua/mobile-app/main/Champion-club/master.m3u8',
          // type: 'application/x-mpegURL',
          // src: 'https://vjs.zencdn.net/v/oceans.mp4',
          // type: 'video/mp4',
          // src: 'https://www.youtube.com/embed/xjS6SftYQaQ',
          // type: 'video/youtube'
        }]
      }, () => {
        setPlayer(videoJs);
        videoJs.on('pause', function () {
          setPause(false);
        });
        videoJs.on('play', function () {
          setPause(true);
        });
      });
    }
  },[dataVideo]);

  const playHandle = () => {
    if (player) {
      player.play()
    } else {
      const ampsd = iFrameRef.current.src.indexOf('?') !== -1 ? '&' : '?';
      iFrameRef.current.src = dataVideo?.stream_url_iFrame + ampsd + "autoplay=1";
      setPause(true);
    }
  };

  return (
    <>
      <div className="iframe">
        <div className="responsive-box">
          <div className="content">
            {dataVideo?.status && (dataVideo?.stream_url || dataVideo?.stream_url_iFrame) && dataVideo?.playing_now && <div className="live">
              <span className="span static">{t('В эфире')}:</span>
              <span className="span name">{dataVideo?.playing_now}</span>
            </div>}
            <div className="js-iframe iframe_box">
              <button
                id="js-play_video"
                className={`pause ${!dataVideo?.status && 'close'} ${!pause && 'active'}`}
                onClick={playHandle}>
                {loadingFunctionApiIndexVideo && !dataVideo && <img src={ico_loading} alt={t('Загрузка')} title={t('Загрузка')} />}
                {!loadingFunctionApiIndexVideo && dataVideo?.status && (dataVideo?.stream_url || dataVideo?.stream_url_iFrame) && <img src={ico_pause} alt={t('Играть')} title={t('Играть')} />}
                {!loadingFunctionApiIndexVideo && !(dataVideo?.stream_url || dataVideo?.stream_url_iFrame) && <img src={ico_close} alt={t('Недоступно')} title={t('Недоступно')} />}
              </button>
              {dataVideo?.status
                && (dataVideo?.stream_url
                  ? <div data-vjs-player><video ref={videoRef} className="video-js vjs-big-play-centered" /></div>
                  : dataVideo?.stream_url_iFrame && <iframe style={{backgroundImage: `url(${img_poster})`}} src={dataVideo?.stream_url_iFrame} ref={iFrameRef} />)
              }
            </div>
            {dataVideo?.status && dataVideo?.playing_next && <div className="then">
              <span className="span static">{t('Далее')}:</span>
              <span className="span name">{dataVideo?.playing_next}</span>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Video);