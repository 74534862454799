import React, { useEffect, useState } from 'react';
import device from "current-device";
import platform from 'platform';
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import Icons from "../Icons";
import {API_RADIO, INDEX_ROUTE} from "../../utils/consts";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useFetching} from "../../hooks/useFetching";
import ApiRadio from "../../services/Radio";

import ico_logo from '../../img/logo-club.svg'
import ico_browser from '../../img/icons/browser.svg'
import ico_android from '../../img/icons/android.svg'
import ico_ios from '../../img/icons/ios.svg'
import ico_windows from '../../img/icons/windows.svg'
import ico_menu_open from '../../img/icons/menu.svg'
import ico_menu_close from '../../img/icons/close_menu.svg'
import ico_radio_green_play from '../../img/icons/radio-play.svg'
import ico_radio_green_pause from '../../img/icons/radio-pause.svg'
import ico_radio_play from '../../img/icons/play.svg'
import ico_radio_pause from '../../img/icons/pause.svg'
import ico_radio from '../../img/icons/radio.png'
import GenerateURL from "../../utils/generateURL";
import {observer} from "mobx-react-lite";
import i18next from "i18next";

const Header = props => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const [bottom, setBottom] = useState(device.desktop() ? 44 : 0);
  const [topPaddingRight, setTopPaddingRight] = useState(0);
  const [centerPaddingRight, setCenterPaddingRight] = useState(24);
  const [muted, setMuted] = useState(true);

  const [dataRadio, setDataRadio] = useState();

  const [FunctionApiRadio] = useFetching(async () => {
    const response = await ApiRadio.getApiRadio(API_RADIO);
    setDataRadio(response.data.radio);
  });

  const OpenMenu = () => {
    const mobile_menu_content = document.getElementById('js-mobile_menu');
    props.setMobileMenu(!props.mobileMenu);
    mobile_menu_content.style.opacity = '1';
    if (device.desktop()) {
      document.body.style.paddingRight = `${props.mobileMenu ? 0 : window.innerWidth - document.body.offsetWidth}px`;
      setTopPaddingRight(props.mobileMenu ? 0 : window.innerWidth - document.body.offsetWidth);
      setCenterPaddingRight(props.mobileMenu ? 24 : window.innerWidth - document.body.offsetWidth + 24);
    }
    document.body.classList.toggle('fixed');
  };

  const CloseMenu = () => {
    props.setMobileMenu(false);
    props.setMobileMenuFooter(false);
    document.body.classList.remove('fixed');
    document.body.classList.remove('footer');
    document.body.style.top = "0px";
    document.body.style.paddingRight = "0px";
    document.querySelector('#js-mobile_menu').classList.remove('footer_breadcrumbs');
    document.querySelector('#js-mobile_menu').classList.remove('footer');
    document.querySelector('#js-mobile_menu').classList.remove('active');
    document.querySelector('#js-mobile_menu').style.opacity = null;
    document.querySelector('#js-mobile_menu').style.height = null;
  };

  useEffect(()=>{
    if (device.desktop()) {
      document.addEventListener('scroll', function () {
        setBottom(window.scrollY + window.innerHeight > document.body.offsetHeight - document.querySelector('.footer').offsetHeight
          ? ((window.scrollY + window.innerHeight) - (document.body.offsetHeight - document.querySelector('.footer').offsetHeight)) + 44
          : 44);
      });
      if (document.body.offsetHeight <= window.innerHeight) {
        setBottom(document.querySelector('.footer').offsetHeight + 44);
      }
    }
  }, [size]);

  useEffect(()=>{
    if (localStorage.getItem('radio')) {
      if (localStorage.getItem('radio') === 'on') {
        setMuted(false)
      }
    } else {
      localStorage.setItem('radio', 'off')
    }
    document.body.addEventListener('click', function () {
      if (localStorage.getItem('radio') && localStorage.getItem('radio') === 'on') setMuted(false)
    });
    document.addEventListener('scroll', function () {
      if (localStorage.getItem('radio') && localStorage.getItem('radio') === 'on') setMuted(false)
    });
  }, []);

  useEffect(()=>{
    FunctionApiRadio();
  }, [i18next.language]);

  const toggleRadio = () => {
    localStorage.setItem('radio', muted ? 'on' : 'off');
    setMuted(!muted)
  };

  return (
    <>
      <header className="header" id="js-header">
        <div className="top" style={{paddingRight: `${topPaddingRight}px`}}>
          {props?.dataGaming?.browser_game && <a className="browser" href={props?.dataGaming?.browser_game} rel='nofollow'>
            <div>
              <img src={ico_browser} alt={t('Играть в браузере')} title={t('Играть в браузере')} />
              <span>{t('играть в браузере')}</span>
            </div>
          </a>}
          {platform?.os?.family && (platform?.os?.family === 'Android') && props?.dataGaming?.android_app && <a className="android" href={props?.dataGaming?.android_app}>
            <div vocab="https://schema.org/" typeof="SoftwareApplication">
              <img src={ico_android} alt={t('Скачать приложение')} title={t('Скачать приложение')} />
              <span>{t('скачать приложение')}</span>
              <span className="none" property="operatingSystem">Android</span>
              <span className="none" property="name">ChampionCasino</span>
              <span className="none" property="applicationCategory" content="GameApplication" />
            </div>
          </a>}
          {platform?.os?.family && (platform?.os?.family?.indexOf('Windows') !== -1) && props?.dataGaming?.windows_app && <a className="android" href={props?.dataGaming?.windows_app}>
            <div vocab="https://schema.org/" typeof="SoftwareApplication">
              <img src={ico_windows} alt={t('Скачать приложение')} title={t('Скачать приложение')} />
              <span>{t('скачать приложение')}</span>
              <span className="none" property="operatingSystem">Windows</span>
              <span className="none" property="name">ChampionCasino</span>
              <span className="none" property="applicationCategory" content="GameApplication" />
            </div>
          </a>}
          {platform?.os?.family && (platform?.os?.family?.indexOf('iOS') !== -1) && props?.dataGaming?.ios_app && <a className="android" href={props?.dataGaming?.ios_app}>
            <div vocab="https://schema.org/" typeof="SoftwareApplication">
              <img src={ico_ios} alt={t('Скачать приложение')} title={t('Скачать приложение')} />
              <span>{t('скачать приложение')}</span>
              <span className="none" property="operatingSystem">iOS</span>
              <span className="none" property="name">ChampionCasino</span>
              <span className="none" property="applicationCategory" content="GameApplication" />
            </div>
          </a>}
        </div>
        <div className="center" style={{paddingRight: `${centerPaddingRight}px`}} itemScope itemType="https://schema.org/Organization">
          <div className="center_content">
            <div className="left_block">
              <NavLink
                to={GenerateURL(INDEX_ROUTE)}
                className="logo"
                itemProp="url"
                onClick={CloseMenu}
              >
                <img itemProp="logo" src={ico_logo} alt="logo"/>
              </NavLink>
            </div>
            <div className="right_block">
              <button className={`js-mobile_click mobile_click ${props.mobileMenu && 'active'}`} onClick={OpenMenu}>
                <img className="open" src={ico_menu_open} alt={t('Открыть меню')} title={t('Открыть меню')} />
                <img className="close" src={ico_menu_close} alt={t('Закрыть меню')} title={t('Закрыть меню')} />
              </button>
            </div>
          </div>
        </div>
        {dataRadio?.status && <>
          <audio src={dataRadio?.url} muted={muted} autoPlay />
          {device.desktop() ? <div className={`radio green ${!muted && 'active'}`} style={{bottom: `${bottom}px`}}>
              <div className="left_block">
                <div className="radio_top">
                  <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" viewBox="0 0 504.4 504.4">
                    <path className="radio_arrow"
                          d="M15.5,267.7c-8.6,0-15.5-6.9-15.5-15.5c0-67.4,26.2-130.7,73.9-178.3C121.5,26.2,184.8,0,252.2,0c8.6,0,15.5,6.9,15.5,15.5S260.8,31,252.2,31C130.2,31,31,130.2,31,252.2C31,260.8,24.1,267.7,15.5,267.7z M430.5,430.5c47.6-47.6,73.9-111,73.9-178.3c0-8.6-6.9-15.5-15.5-15.5s-15.5,6.9-15.5,15.5c0,59.1-23,114.6-64.8,156.4s-97.3,64.8-156.4,64.8c0,0,0,0,0,0c-8.6,0-15.5,6.9-15.5,15.5c0,8.6,6.9,15.5,15.5,15.5c0,0,0,0,0,0C319.6,504.4,382.9,478.2,430.5,430.5z"/>
                    <path className="radio_arrow"
                          d="M441,252.2c0-8.6-6.9-15.5-15.5-15.5s-15.5,6.9-15.5,15.5c0,87-70.8,157.8-157.8,157.8c-8.6,0-15.5,6.9-15.5,15.5s6.9,15.5,15.5,15.5C356.3,441,441,356.3,441,252.2z M94.4,252.2c0-42.2,16.4-81.8,46.2-111.6c29.8-29.8,69.4-46.2,111.6-46.2c0,0,0,0,0,0c8.6,0,15.5-6.9,15.5-15.5c0-8.6-6.9-15.5-15.5-15.5c0,0,0,0,0,0c-50.4,0-97.8,19.6-133.5,55.3C83,154.4,63.4,201.8,63.4,252.2c0,8.6,6.9,15.5,15.5,15.5S94.4,260.8,94.4,252.2z"/>
                    <path className="radio_arrow"
                          d="M158.6,252.2c0-51.6,42-93.6,93.6-93.6c8.6,0,15.5-6.9,15.5-15.5s-6.9-15.5-15.5-15.5c-68.7,0-124.6,55.9-124.6,124.6c0,8.6,6.9,15.5,15.5,15.5S158.6,260.8,158.6,252.2z M376.8,252.2c0-8.6-6.9-15.5-15.5-15.5s-15.5,6.9-15.5,15.5c0,51.6-42,93.6-93.6,93.6c-8.6,0-15.5,6.9-15.5,15.5s6.9,15.5,15.5,15.5C320.9,376.8,376.8,320.9,376.8,252.2z"/>
                    <circle className="radio_dot" cx="252.2" cy="252.2" r="41"/>
                  </svg>
                </div>
                <div className="radio_bottom">
                  <span>Champion Radio</span>
                  <div className="marquee"><span className="name">{dataRadio?.playing_now}</span></div>
                </div>
              </div>
              <div className="right_block">
                <button className={`button_play ${muted && 'pause'}`} id="js-play" onClick={toggleRadio}>
                  <img className="play" src={ico_radio_green_play} alt={t('Воспроизвести радио')} title={t('Воспроизвести радио')} />
                  <img className="pause" src={ico_radio_green_pause} alt={t('Остановить радио')} title={t('Остановить радио')} />
                </button>
              </div>
            </div>
            :
            <div className={`radio ${!muted && 'active'}`} style={{bottom: `${bottom}px`}}>
              <div className="left_block">
                <div className="radio_top">
                  <img src={ico_radio} alt="ico_radio" />
                  <span>ChampionRadio</span>
                </div>
                <div className="radio_bottom">
                  <span className="stat">{t('Играет')}:</span>
                  {/*<div className="marquee"><span className="name">{dataRadio?.playing_now}</span></div>*/}
                  <span className="name">{dataRadio?.playing_now}</span>
                </div>
              </div>
              <div className="right_block">
                <button className={`button_play ${muted && 'pause'}`} id="js-play" onClick={toggleRadio}>
                  <img className="play" src={ico_radio_play} alt={t('Воспроизвести радио')} title={t('Воспроизвести радио')} />
                  <img className="pause" src={ico_radio_pause} alt={t('Остановить радио')} title={t('Остановить радио')} />
                </button>
              </div>
            </div>}
        </>}
        <div className="social">
          <Icons dataSocial={props.dataSocial} />
        </div>
      </header>
    </>
  );
};

export default observer(Header);