import {
  ACTIONS_DETAILS_ROUTE,
  ACTIONS_ROUTE,
  ANNOUNCEMENT_ARCHIVE_ROUTE,
  ANNOUNCEMENT_ROUTE, BLOG_DETAILS_ROUTE, BLOG_ROUTE,
  INDEX_ROUTE, VIDEO_CATEGORY_DETAILS_ROUTE, VIDEO_CATEGORY_ROUTE, VIDEO_ROUTE, WINNERS_ROUTE
} from "./utils/consts";
import IndexPage from "./pages/IndexPage";
import AnnouncementPage from "./pages/AnnouncementPage";
import AnnouncementArchivePage from "./pages/AnnouncementArchivePage";
import WinnersPage from "./pages/WinnersPage";
import BlogPage from "./pages/BlogPage";
import ActionsPage from "./pages/ActionsPage";
import ActionsDetailsPage from "./pages/ActionsDetailsPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import VideoPage from "./pages/VideoPage";
import VideoCategoryPage from "./pages/VideoCategoryPage";
import VideoCategoryDetailsPage from "./pages/VideoCategoryDetailsPage";
export const publicRoutes = [
  {path: INDEX_ROUTE, Component: IndexPage, name: "Главная"},
  {path: ANNOUNCEMENT_ROUTE, Component: AnnouncementPage, name: "Анонсы событий"},
  {path: ANNOUNCEMENT_ARCHIVE_ROUTE, Component: AnnouncementArchivePage, name: "Архив анонсов"},
  {path: WINNERS_ROUTE, Component: WinnersPage, name: "Победители"},
  {path: BLOG_ROUTE, Component: BlogPage, name: "Блог"},
  {path: BLOG_DETAILS_ROUTE, Component: BlogDetailsPage, name: "Блог"},
  {path: ACTIONS_ROUTE, Component: ActionsPage, name: "Акции"},
  {path: ACTIONS_DETAILS_ROUTE, Component: ActionsDetailsPage, name: "Акции"},
  {path: VIDEO_ROUTE, Component: VideoPage, name: "Видео"},
  {path: VIDEO_CATEGORY_ROUTE, Component: VideoCategoryPage, name: "Видео"},
  {path: VIDEO_CATEGORY_DETAILS_ROUTE, Component: VideoCategoryDetailsPage, name: "Видео"},
];