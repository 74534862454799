import React from 'react';
import {observer} from "mobx-react-lite";

const TournamentsTrLoading = () => {
  return (
    <div className="loading-dots">
      <div className="loading-dots--dot" />
      <div className="loading-dots--dot" />
      <div className="loading-dots--dot" />
      <div className="loading-dots--dot" />
      <div className="loading-dots--dot" />
    </div>
  );
};

export default observer(TournamentsTrLoading);