import React from 'react';
import {observer} from "mobx-react-lite";
import Tournaments from "../components/Index/Tournaments";
import Announcements from "../components/Index/Announcements";
import Video from "../components/Index/Video";

import '../style/partials/_index.scss'
import Seo from "../components/Seo";

const IndexPage = () => {
  return (
    <>
      <Seo />
      <div className="index container-fluid">
        <Video />
        <div className="container">
          <Announcements />
          <Tournaments />
          {/*<Merch />*/}
        </div>
      </div>
    </>
  );
};

export default observer(IndexPage);