import React, {useEffect, useRef, useState} from 'react';
import {ACTIONS_ROUTE, API_ACTIONS, INDEX_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";
import Box from "../components/Actions/box";
import Loading from "../components/Loading";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import {useObserver} from "../hooks/useObserver";
import Seo from "../components/Seo";

import '../style/partials/_blog.scss'
import {observer} from "mobx-react-lite";
import NoResult from "../components/NoResult";
import Breadcrumbs from "../components/Breadcrumbs";

const ActionPage = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState();
  // const [totalPages, setTotalPages] = useState(0);
  // const [limit] = useState(6);
  const [page, setPage] = useState(0);
  const lastElement = useRef();

  const [fetchPosts, isPostsLoading] = useFetching(async (page) => {
    const response = await PostService.getAll(API_ACTIONS, page);
    setPosts([...posts, ...response.data?.sale_list]);
    setPost(response.data?.sale_list.length);
    // const totalCount = response.headers['x-total-count'];
    // setTotalPages(getPageCount(totalCount, limit));
  });

  useObserver(lastElement, post > 0, isPostsLoading, () => {
    setPage(page + 1)
  });

  useEffect(() => {
    fetchPosts(page);
  }, [page]);

  return (
    <>
      <Seo page="sale" />
      <div className="blog container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content="4.6" />
          <meta property="ratingCount" content="1743" />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: ACTIONS_ROUTE, name: 'Акции'}
        ]}/>
        <div className="container">
          <div className="title">
            <h1>{t('Акции')}</h1>
          </div>
          <div className="boxing" id="Boxing">
            {posts.length > 0 && posts.map(item => <Box key={item.id} item={item}/>)}
          </div>
          <div ref={lastElement} className="lastElement" />
          {isPostsLoading
            ? <Loading />
            : !posts.length && <NoResult />
          }
        </div>
      </div>
    </>
  );
};

export default observer(ActionPage);