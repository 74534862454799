import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import GenerateURL from "../utils/generateURL";
import {API_VIDEO_DETAIL, INDEX_ROUTE, VIDEO_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import Share from "../components/Share";
import SideBar from "../components/Video/SideBar";
import ErrorPage404 from "./ErrorPage404";
import Loading from "../components/Loading";
import {LanguageContext} from "../App";
import {useFetching} from "../hooks/useFetching";
import ApiVideoDetails from "../services/VideoDetails";
import {Helmet} from "react-helmet";
import i18next from "i18next";
import generateLanguageURL from "../utils/generateLanguageURL";
import parse from "html-react-parser";

import ico_loading from "../img/icons/loading.svg";
import ico_pause from "../img/video/start-video-button.png";
import ico_close from "../img/icons/close_menu.svg";
import '../style/partials/_blog.scss'
import '../style/partials/_details.scss'
import '../style/partials/_video.scss'
import Breadcrumbs from "../components/Breadcrumbs";

const VideoCategoryDetailsPage = () => {
  const { t } = useTranslation();
  const [item, setItem] = useState();

  const [pause, setPause] = useState(false);
  const iFrameRef = useRef(null);
  const params = useParams();
  const [languageContext, setLanguageContext] = useContext(LanguageContext);

  const [FunctionApiVideoDetails, isLoading, errorMessage, errorStatus, errorPage] = useFetching(async (id) => {
    const response = await ApiVideoDetails.getApiVideoDetails(API_VIDEO_DETAIL, id);
    setItem(response.data);
  });

  useEffect(() => {
    FunctionApiVideoDetails(params.id);
    // setTimeout(()=>{
    //   setItem({
    //     "id": 57,
    //     "publication_date": "2022-03-04",
    //     "preview_image": "/media/media/VideoPreview/5adf1b97742a65d0a3c98299c545570b_BnP064W.jpg",
    //     "author": "ChampionClub",
    //     "seo_urls": {
    //       "def_url": "pervyi-strim",
    //       "ru_url": "pervyi-strim",
    //       "uk_url": "pershii-strim"
    //     },
    //     "rating": 0.0,
    //     "voices": 0,
    //     "views": 41,
    //     "seo_title": "Первый стрим",
    //     "seo_description": "Первый стрим",
    //     "og:description": "Первый стрим",
    //     "category": {
    //       "id": 22,
    //       "name": "Стримеры",
    //       "uk_slug": "strimeri",
    //       "ru_slug": "strimery"
    //     },
    //     "name": "Первый стрим",
    //     "text": "<p>Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое не получается при простой дубликации &quot;Здесь ваш текст.. Здесь ваш текст.. Здесь ваш текст..&quot; Многие программы электронной вёрстки и редакторы HTML используют Lorem Ipsum в качестве текста по умолчанию, так что поиск по ключевым словам &quot;lorem ipsum&quot; сразу показывает, как много веб-страниц всё ещё дожидаются своего настоящего рождения. За прошедшие годы текст Lorem Ipsum получил много версий. Некоторые версии появились по ошибке, некоторые - намеренно (например, юмористические варианты).</p>",
    //     "url": "https://www.youtube.com/embed/7UNIwBtYEDE",
    //     "url_iFrame": "",
    //     "recommendation": [
    //       {
    //         "id": 60,
    //         "name": "test123213",
    //         "title": "3455345345",
    //         "url": {
    //           "category_slug": "chempionska-tusovka",
    //           "video_slug": "test123213"
    //         },
    //         "preview_image": "/media/media/VideoPreview/56711-kot_suhaya_trava_seamskij.jpg",
    //         "lang": "uk"
    //       },
    //       {
    //         "id": 59,
    //         "name": "test_uk",
    //         "title": "test_uk",
    //         "url": {
    //           "category_slug": "chempionska-tusovka",
    //           "video_slug": "test-uk"
    //         },
    //         "preview_image": "/media/media/VideoPreview/1.jpg",
    //         "lang": "uk"
    //       },
    //       {
    //         "id": 58,
    //         "name": "Тест 14.03",
    //         "title": "Тестовая",
    //         "url": {
    //           "category_slug": "chempionskaia-tusovka",
    //           "video_slug": "test-14-03"
    //         },
    //         "preview_image": "/media/media/VideoPreview/041-741x367_8OZWkNJ.jpg",
    //         "lang": "ru"
    //       },
    //       {
    //         "id": 54,
    //         "name": "Лидеры выигрышей",
    //         "title": "Lorem Ipsum - это текст-\"рыба\", часто используемый в печати и вэб-дизайне.",
    //         "url": {
    //           "category_slug": "chempionskii-chas",
    //           "video_slug": "lidery-vyigryshei"
    //         },
    //         "preview_image": "/media/media/VideoPreview/pic-00892_jRbMURi.jpg",
    //         "lang": "ru"
    //       }
    //     ]
    //   });
    // }, 1000)
  }, [params.id]);

  useEffect(()=>{
    setLanguageContext({
      'uk': item?.seo_urls?.uk_url ? `${VIDEO_ROUTE}/${item?.category?.uk_slug}/${item?.seo_urls?.uk_url}` : '',
      'ru': item?.seo_urls?.ru_url ? `${VIDEO_ROUTE}/${item?.category?.ru_slug}/${item?.seo_urls?.ru_url}` : ''
    });
  }, [!isLoading && item]);


  const Time = (date) => {
    const options = {month: 'long', day: 'numeric', year: "numeric"};
    return new Date(date).toLocaleDateString(i18next.language, options);
  };

  const playHandle = () => {
    const ampsd = iFrameRef.current.src.indexOf('?') !== -1 ? '&' : '?';
    iFrameRef.current.src = iFrameRef.current.src + ampsd + "autoplay=1";
    setPause(true);
  };

  return errorPage && !isLoading ? <ErrorPage404 status={errorStatus} message={errorMessage} /> : isLoading ? <Loading /> :  (
    <>
      {item && <Helmet>
        <html lang={i18next.language} />
        <title>{item?.seo_title}</title>
        <meta name="description" content={item?.seo_description} />
        <meta property="og:description" content={item["og:description"]} />
        <link rel="alternate" hrefLang="uk-ua" href={item?.seo_urls?.uk_url ? window.location.origin.toString()+('/'+generateLanguageURL(VIDEO_ROUTE+'/'+item?.category?.uk_slug+'/'+item.seo_urls.uk_url, "uk")).replace('//', '/') : ''} />
        <link rel="alternate" hrefLang="ru-ua" href={item?.seo_urls?.ru_url ? window.location.origin.toString()+('/'+generateLanguageURL(VIDEO_ROUTE+'/'+item?.category?.ru_slug+'/'+item.seo_urls.ru_url, "ru")).replace('//', '/') : ''} />
        <link rel="alternate" hrefLang="x-default" href={item?.seo_urls?.def_url ? window.location.origin.toString()+('/'+generateLanguageURL(VIDEO_ROUTE+'/'+item?.category?.ru_slug+'/'+item.seo_urls.def_url, "ru")).replace('//', '/') : ''} />
      </Helmet>}
      {item && <div className="video_details video details blog container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content={item?.rating} />
          <meta property="ratingCount" content={item?.voices} />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: VIDEO_ROUTE, name: 'Видео'},
          {url: VIDEO_ROUTE+'/'+item.category[`${i18next.language}_slug`], name: item.category.name},
          {url: VIDEO_ROUTE+'/'+item.category[`${i18next.language}_slug`+'/'+params.id], name: item.name}
        ]}/>
        <div className="container">
          <div className="content">
            <div className="content_box">
              <div className="top">
                <div className="right_block">
                  <div className="img">
                    <div className="content_video">
                      <button className={`js-click_play_video pause ${!isLoading && !(item?.url || item?.url_iFrame) && 'close'} ${!pause && 'active'}`} onClick={playHandle}>
                        {isLoading && <img src={ico_loading} alt={t('Загрузка')} title={t('Загрузка')} />}
                        {!isLoading && (item?.url || item?.url_iFrame) && <img src={ico_pause} alt={t('Играть')} title={t('Играть')} />}
                        {!isLoading && !(item?.url || item?.url_iFrame) && <img src={ico_close} alt={t('Недоступно')} title={t('Недоступно')} />}
                      </button>
                      {!pause && <div className="content_img">
                        <img src={item?.preview_image} alt="poster" />
                      </div>}
                      <div className="content_iframe">
                        {(item?.url || item?.url_iFrame) && <iframe
                          ref={iFrameRef}
                          width="560"
                          height="315"
                          src={item?.url || item?.url_iFrame}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="left_block">
                  <div className="title">
                    <h1>{item?.name}</h1>
                  </div>
                  <div className="social">
                    <link itemProp="url" href={window.location.origin.toString()+(GenerateURL(VIDEO_ROUTE+'/'+item.category.slug+'/'+params.id))} />
                    <meta itemProp="datePublished" content={item?.publication_date} />
                    <meta itemProp="headline" content={item?.name} />
                    {item?.category?.name && <span>{item?.category?.name}</span>}
                    {item?.publication_date && <span>{Time(item?.publication_date)}</span>}
                    {item?.author && <span className="author" itemProp="author">{item?.author}</span>}
                    {item?.views && <span className="author">{t('просмотров')}: {item?.views}</span>}
                    <Share url={window.location.origin.toString()+(GenerateURL(VIDEO_ROUTE+'/'+item?.category[`${i18next.language}_slug`]+'/'+params.id))} />
                  </div>
                </div>
              </div>
              <div className="editor content_text">
                {item?.text && parse(item?.text)}
              </div>
              <div className="bottom">
                <div className="social">
                  <Share url={window.location.origin.toString()+(GenerateURL(VIDEO_ROUTE+'/'+item?.category[`${i18next.language}_slug`]+'/'+params.id))} />
                </div>
              </div>
            </div>
            <SideBar items={item?.recommendation} page={VIDEO_ROUTE} />
          </div>
        </div>
      </div>}
    </>
  );
};

export default observer(VideoCategoryDetailsPage);