import React from 'react';
import {ACTIONS_ROUTE, ANNOUNCEMENT_ROUTE} from "../../utils/consts";
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import i18next from "i18next";
import GenerateURL from "../../utils/generateURL";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";

const ArchiveBox = ({item}) => {
  const { t } = useTranslation();

  const Time = (start_time, end_time) => {
    const options = {weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
    const start = new Date(start_time).toLocaleDateString(i18next.language, options);
    const start_result = start[0].toUpperCase() + start.slice(1).replace('.', '');
    const end = new Date(end_time).toLocaleDateString(i18next.language, options);
    const end_result = end[0].toUpperCase() + end.slice(1).replace('.', '');

    return (
      <span className="time">{start_result} - {end_result}</span>
    )
  };

  return (
    <div className="box" id={item.id} itemScope itemType="http://schema.org/Article">
      <div className="box_right">
        <div className="top">
          <meta itemProp="datePublished" content={item.start_date} />
          <link itemProp="url" href={GenerateURL(ANNOUNCEMENT_ROUTE+'#'+item.id)} />
          <h3 itemProp="name">{item?.name}</h3>
          {Time(item?.start_date, item?.end_date)}
        </div>
        <div className="bottom" itemProp="articleBody">
          {item?.text && parse(item?.text)}
          {item?.sale && <Link to={item.sale && GenerateURL(ACTIONS_ROUTE+'/'+item.sale)} className="sale">
            <span>{t('Подробнее про акцию')}</span>
          </Link>}
        </div>
      </div>
    </div>
  );
};

export default observer(ArchiveBox);