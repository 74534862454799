import React, { useState, useEffect, useContext } from 'react';
import {useParams} from "react-router-dom";
import {API_VIDEO_CATEGORY_DETAILS, INDEX_ROUTE, VIDEO_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";

import Loading from "../components/Loading";
import {useFetching} from "../hooks/useFetching";
import {Helmet} from "react-helmet";
import i18next from "i18next";
import generateLanguageURL from "../utils/generateLanguageURL";
import ApiVideoCategory from "../services/VideoCategory";
import ErrorPage404 from "./ErrorPage404";
import {LanguageContext} from "../App";

import '../style/partials/_video.scss'
import Container from "../components/VideoCategory/Container";
import Breadcrumbs from "../components/Breadcrumbs";

const VideoCategoryPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [item, setItem] = useState(
    // {
    //   "category": {
    //     "id": 20,
    //     "name": "Чемпіонська тусовка",
    //     "seo_title": "Чемпіонська тусовка",
    //     "seo_description": "Чемпіонська тусовка",
    //     "seo_urls": {
    //       "def_url": "chempionskaia-tusovka",
    //       "ru_url": "chempionskaia-tusovka",
    //       "uk_url": "chempionska-tusovka"
    //     }
    //   },
    //   "subcategory_list": [
    //     {
    //       "id": 17,
    //       "name": "Найкращі моменти"
    //     }
    //   ]
    // }
  );
  const [languageContext, setLanguageContext] = useContext(LanguageContext);

  const [FunctionApiVideoCategory, isLoading, errorMessage, errorStatus, errorPage] = useFetching(async (slug) => {
    const response = await ApiVideoCategory.getApiVideoCategory(API_VIDEO_CATEGORY_DETAILS, slug);
    setItem(response.data);
  });

  useEffect(() => {
    FunctionApiVideoCategory(params.id);
  }, [params.id]);

  useEffect(()=>{
    setLanguageContext({
      'uk': item?.category?.seo_urls?.uk_url ? `${VIDEO_ROUTE}/${item?.category?.seo_urls?.uk_url}` : '',
      'ru': item?.category?.seo_urls?.ru_url ? `${VIDEO_ROUTE}/${item?.category?.seo_urls?.ru_url}` : ''
    });
  }, [!isLoading && item]);

  return errorPage && !isLoading ? <ErrorPage404 status={errorStatus} message={errorMessage} /> : isLoading ? <Loading /> :  (
    <>
      {item?.category && <Helmet>
        <html lang={i18next.language} />
        <title>{item?.category?.seo_title}</title>
        <meta name="description" content={item?.category?.seo_description} />
        <link rel="alternate" hrefLang="uk-ua" href={item?.category?.seo_urls?.uk_url ? window.location.origin.toString()+('/'+generateLanguageURL(VIDEO_ROUTE+'/'+item.category.seo_urls.uk_url, "uk")).replace('//', '/') : ''} />
        <link rel="alternate" hrefLang="ru-ua" href={item?.category?.seo_urls?.ru_url ? window.location.origin.toString()+('/'+generateLanguageURL(VIDEO_ROUTE+'/'+item.category.seo_urls.ru_url, "ru")).replace('//', '/') : ''} />
        <link rel="alternate" hrefLang="x-default" href={item?.category?.seo_urls?.def_url ? window.location.origin.toString()+('/'+generateLanguageURL(VIDEO_ROUTE+'/'+item.category.seo_urls.def_url, "ru")).replace('//', '/') : ''} />
      </Helmet>}
      {(item?.category && item?.subcategory_list) ? <div className="video video_category container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content='4.8' />
          <meta property="ratingCount" content='1498' />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: VIDEO_ROUTE, name: 'Видео'},
          {url: VIDEO_ROUTE+'/'+params.id, name: item?.category?.name}
        ]}/>
        <Container
          category_id={item?.category?.id}
          name={item?.category?.name}
          subcategory_list={item?.subcategory_list}
        />
      </div> : <Loading/>}
    </>
  );
};

export default observer(VideoCategoryPage);