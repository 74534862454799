import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App';

import "current-device";
import './i18n'
import './style/main.scss'
import Loader from "./components/Loader";

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
