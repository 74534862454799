import React, { useState, useRef, useEffect } from 'react';
import Category from "../Video/Category";
import SubCategoryBox from "../Video/SubCategoryBox";
import Loading from "../Loading";
import NoResult from "../NoResult";
import {useFetching} from "../../hooks/useFetching";
import PostService from "../../API/PostService";
import {API_VIDEO_CATEGORY_LIST} from "../../utils/consts";
import {useObserver} from "../../hooks/useObserver";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

const Container = ({category_id, name, subcategory_list}) => {
  const {t} = useTranslation();
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState();
  const [subCategoryId, setSubCategoryId] = useState('');
  const [formSubmit, setFormSubmit] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState('allvideos');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const lastElement = useRef();

  const [fetchPosts, isPostsLoading] = useFetching(async (page) => {
    const response = await PostService.getAll(`${API_VIDEO_CATEGORY_LIST}?search_keyword=${search}&subcategory_id=${subCategoryId}&subcategory_name=${subCategoryName}&category_id=${category_id}`, page);
    setPosts([...posts, ...response.data?.video_list]);
    setPost(response.data?.video_list.length);
  });

  useObserver(lastElement, post > 0, isPostsLoading, () => {
    setPage(page + 1)
  });

  useEffect(() => {
    if (!isPostsLoading) {
      fetchPosts(page);
    }
  }, [page, subCategoryName, subCategoryId, formSubmit]);

  const tabsClick = (e) => {
    const button = e.target.closest('button');
    button?.closest('.tab')?.querySelector('button.active')?.classList.remove('active');
    button.classList.add('active');
    setPage(0);
    setPosts([]);
    setSearch('');
    setSubCategoryId(button.dataset.id);
    setSubCategoryName(button.dataset.name);
  };

  const formHandle = (e) => {
    e.preventDefault();
    if (e.target.querySelector('input').value.length >= 3) {
      setPage(0);
      setPosts([]);
      setFormSubmit(!formSubmit);
    }
  };

  return (
    <div className="container">
      <div className="title">
        <h1>{name}</h1>
      </div>
      <Category />
      <div className="filter">
        <div className="tab">
          <OverlayScrollbarsComponent>
            <button
              data-id=''
              data-name='allvideos'
              className='active'
              onClick={tabsClick}
              type="button">
              <span>{t('Все видео')}</span>
            </button>
            <button
              data-id=''
              data-name='recomendvideos'
              onClick={tabsClick}
              type="button">
              <span>{t('Рекомендуем тебе')}</span>
            </button>
            {subcategory_list && subcategory_list.map(item=><button
              key={item.id}
              data-id={item.id}
              data-name=''
              onClick={tabsClick}
              type="button">
              <span>{item.name}</span>
            </button>)}
          </OverlayScrollbarsComponent>
        </div>
        <div className="search">
          <form action="" onSubmit={formHandle}>
            <div className="input">
              <input
                type="text"
                name="search_keyword"
                placeholder={t('Поиск по ключевому слову')}
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="sub_category">
        <div className="sub_category_boxing">
          {posts.length > 0 && posts.map(item => <SubCategoryBox key={item.id} item={item}/>)}
          <div className="sub_category_box none" />
          <div className="sub_category_box none" />
        </div>
        {isPostsLoading
          ? <Loading />
          : (!posts.length || posts.length === 0) && <NoResult />
        }
        <div ref={lastElement} className="lastElement" />
      </div>
    </div>
  );
};

export default observer(Container);