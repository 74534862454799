import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";

import {useFetching} from "../../hooks/useFetching";
import {API_INDEX_ANNOUNCEMENT} from "../../utils/consts";
import ApiIndexAnnouncement from "../../services/IndexAnnouncement";
import {observer} from "mobx-react-lite";
import AnnouncementsBox from "../Announcements/AnnouncementsBox";
import Loading from "../Loading";
import NoResult from "../NoResult";

const Announcements = () => {
  const { t } = useTranslation();
  const [dataIndexAnnouncement, setDataIndexAnnouncement] = useState();

  const [FunctionApiIndexAnnouncement, loadingFunctionApiIndexAnnouncement] = useFetching(async () => {
    const response = await ApiIndexAnnouncement.getApiIndexAnnouncement(API_INDEX_ANNOUNCEMENT, 5);
    setDataIndexAnnouncement(response.data?.announcement_list);
  });

  useEffect(() => {
    FunctionApiIndexAnnouncement();
  }, []);

  return (
    <>
      <div className="event_announcements">
        <h1>{t('Анонсы событий')}</h1>
        {loadingFunctionApiIndexAnnouncement
          ? <Loading />
          : dataIndexAnnouncement?.length > 0
            ? <div className="boxing">{dataIndexAnnouncement.map(item=><AnnouncementsBox item={item} href={true} logotype={true} />)}</div>
            : <NoResult />
        }
      </div>
    </>
  );
};

export default observer(Announcements);