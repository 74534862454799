import React from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {ACTIONS_ROUTE} from "../../utils/consts";
import LinesEllipsis from 'react-lines-ellipsis'
import {observer} from "mobx-react-lite";
import i18next from "i18next";
import {default_language} from "../../utils/lang";
import device from "current-device";

const Box = ({item}) => {
  const { t } = useTranslation();

  return (
    <div className="box" itemScope="" itemType="http://schema.org/Article">
      <div className="box_left">
        <img itemProp="image" src={item.preview_image} alt={`${item.name} ChampionClub`} title={`${item.name} ChampionClub`} />
      </div>
      <div className="box_right">
        <meta itemProp="headline" content={item.name} />
        <Link
          itemProp="url"
          onClick={()=>i18next.changeLanguage(item.lang)}
          to={`${item.lang === default_language ? `` : `/${item.lang}`}${ACTIONS_ROUTE}/${item.slug}`}>
          <h2 itemProp="name">{item.name}</h2>
          <p className="length" itemProp="articleBody">
            <LinesEllipsis
              text={item.title}
              maxLine={device.mobile() ? 1 : 2}
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
          </p>
          <span className="button">{t('Подробнее')}</span>
        </Link>
      </div>
    </div>
  );
};

export default observer(Box);