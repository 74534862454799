import React, { useState, useEffect, useRef } from 'react';
import {ANNOUNCEMENT_ARCHIVE_ROUTE, ANNOUNCEMENT_ROUTE, API_ARCHIVE, INDEX_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";

import '../style/partials/_announcements.scss'
import Seo from "../components/Seo";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import {useObserver} from "../hooks/useObserver";
import Loading from "../components/Loading";
// import {getPageCount} from "../utils/pages";
import ArchiveBox from "../components/Announcements/ArchiveBox";
import {observer} from "mobx-react-lite";
import NoResult from "../components/NoResult";
import Breadcrumbs from "../components/Breadcrumbs";

const AnnouncementArchivePage = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState();
  // const [totalPages, setTotalPages] = useState(0);
  // const [limit] = useState(6);
  const [page, setPage] = useState(0);
  const lastElement = useRef();

  const [fetchPosts, isPostsLoading] = useFetching(async (page) => {
    const response = await PostService.getAll(API_ARCHIVE, page);
    setPosts([...posts, ...response.data?.announcement_list]);
    setPost(response.data?.announcement_list.length);
    // const totalCount = response.headers['x-total-count'];
    // setTotalPages(getPageCount(totalCount, limit));
  });

  useObserver(lastElement, post > 0, isPostsLoading, () => {
    setPage(page + 1)
  });

  useEffect(() => {
    fetchPosts(page);
  }, [page]);

  return (
    <>
      <Seo page="archive" />
      <div className="announcements container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content="4.2" />
          <meta property="ratingCount" content="1555" />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: ANNOUNCEMENT_ROUTE, name: 'Анонсы событий'},
          {url: ANNOUNCEMENT_ARCHIVE_ROUTE, name: 'Архив анонсов'},
        ]}/>
        <div className="container">
          <div className="event_announcements">
            <h1>{t('Архив анонсов')}</h1>
            <div className="boxing">
              {posts.length > 0 && posts.map(item => <ArchiveBox key={item.id} item={item}/>)}
              <div ref={lastElement} className="lastElement" />
              {isPostsLoading
                ? <Loading />
                : !posts.length && <NoResult />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AnnouncementArchivePage);