import React from 'react';
import {Link} from "react-router-dom";
import GenerateURL from "../../utils/generateURL";
import {useTranslation} from "react-i18next";

const Breadcrumbs = ({items}) => {
  const {t} = useTranslation();

  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs_box" vocab="https://schema.org/" typeof="BreadcrumbList">
        {items.map((item, i) => {
         return (
           <li property="itemListElement" typeof="ListItem">
             <Link to={GenerateURL(item.url)} property="item" typeof="WebPage">
               <span property="name">{t(item.name)}</span></Link>
             <meta property="position" content={i+1}/>
           </li>
         )
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;