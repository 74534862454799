import React from 'react';
import {useLocation} from 'react-router-dom';
import Icons from "../Icons";
import {
  ACTIONS_ROUTE,
  ANNOUNCEMENT_ROUTE,
  BLOG_ROUTE,
  INDEX_ROUTE,
  VIDEO_ROUTE,
  WINNERS_ROUTE
} from "../../utils/consts";
import Language from "../Language";
import {NavLink} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import GenerateURL from "../../utils/generateURL";
import {observer} from "mobx-react-lite";

const MobileMenu = props => {
  const { t } = useTranslation();
  const location = useLocation();

  const toggle = () => {
    props.setMobileMenu(false);
    props.setMobileMenuFooter(false);
    document.body.classList.remove('fixed');
    document.body.classList.remove('footer');
    document.body.style.top = "0px";
    document.body.style.paddingRight = "0px";
    document.querySelector('#js-mobile_menu').classList.remove('footer_breadcrumbs');
    document.querySelector('#js-mobile_menu').classList.remove('footer');
    document.querySelector('#js-mobile_menu').classList.remove('active');
    document.querySelector('#js-mobile_menu').style.opacity = null;
    document.querySelector('#js-mobile_menu').style.height = null;
  };

  return (
    <div id="js-mobile_menu" className={`mobile_menu ${props.mobileMenu ? 'active' : ''} ${props.mobileMenuFooter ? 'footer' : ''} ${(props.mobileMenuFooter && (location.pathname === INDEX_ROUTE || location.pathname === INDEX_ROUTE+'uk')) ? 'footer_breadcrumbs' : ''}`}>
      <ul>
        <li>
          <NavLink
            onClick={toggle}
            to={GenerateURL(INDEX_ROUTE)}
            exact
          >{t('Главная')}</NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={GenerateURL(ANNOUNCEMENT_ROUTE)}
            exact
          >{t('Анонсы событий')}</NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={GenerateURL(WINNERS_ROUTE)}
            exact
          >{t('Победители')}</NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={GenerateURL(BLOG_ROUTE)}
            exact
          >{t('Блог')}</NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={GenerateURL(ACTIONS_ROUTE)}
            exact
          >{t('Акции')}</NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={GenerateURL(VIDEO_ROUTE)}
            exact
          >{t('Видео')}</NavLink>
        </li>
      </ul>
      <div className="social">
        <Icons dataSocial={props.dataSocial} />
      </div>
      <Language setMobileMenu={props.setMobileMenu} setMobileMenuFooter={props.setMobileMenuFooter} />
    </div>
  );
};

export default observer(MobileMenu);