import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {INDEX_ROUTE} from "../../utils/consts";
import {NavLink, useLocation} from "react-router-dom";
import platform from "platform";
import {useWindowSize} from "../../hooks/useWindowSize";
import device from "current-device";
import GenerateURL from "../../utils/generateURL";
import {observer} from "mobx-react-lite";

import ico_browser from "../../img/icons/browser.svg";
import ico_android from "../../img/icons/android.svg";
import ico_windows from "../../img/icons/windows.svg";
import ico_menu_open from "../../img/icons/menu.svg";
import ico_menu_close from "../../img/icons/close_menu.svg";
import ico_logo from "../../img/logo-club.svg";
import ico_ios from "../../img/icons/ios.svg";

const Footer = props => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const location = useLocation();
  const [bottom, setBottom] = useState(44);
  const [display, setDisplay] = useState('none');
  const [topPaddingRight, setTopPaddingRight] = useState(0);
  const [paddingRight, setPaddingRight] = useState(24);

  const OpenMenu = () => {
    props.setMobileMenu(!props.mobileMenu);
    props.setMobileMenuFooter(!props.mobileMenuFooter);
    document.body.classList.toggle('footer');

    const mobile_menu_content = document.getElementById('js-mobile_menu');
    mobile_menu_content.style.top = '-100vh';
    mobile_menu_content.style.opacity = '0';
    document.body.style.top = '-' + (document.body.offsetHeight - window.innerHeight) + 'px';
    mobile_menu_content.classList.add('footer');
    mobile_menu_content.style.height = `${window.innerHeight - document.querySelector('footer.footer').offsetHeight}px`;
    if (document.body.classList.contains('footer')) {
      setTimeout(function () {
        mobile_menu_content.style.top = null;
        mobile_menu_content.style.opacity = '1';
      }, 100);
    }
    if (device.desktop()) {
      document.body.style.paddingRight = `${props.mobileMenu ? 0 : window.innerWidth - document.body.offsetWidth}px`;
      setTopPaddingRight(props.mobileMenu ? 0 : window.innerWidth - document.body.offsetWidth);
      setPaddingRight(props.mobileMenu ? 24 : window.innerWidth - document.body.offsetWidth + 24);
    }
    document.body.classList.toggle('fixed');
    if (!document.body.classList.contains('fixed')) {
      mobile_menu_content.style.opacity = '1';
      mobile_menu_content.style.height = null;
      mobile_menu_content.style.top = '-100vh';
      document.body.style.top = null;
      window.scrollTo(0, document.body.offsetHeight - window.innerHeight);
      setTimeout(function () {
        mobile_menu_content.style.top = null;
        mobile_menu_content.style.opacity = null;
      }, 100);
    }
  };

  const CloseMenu = () => {
    props.setMobileMenu(false);
    props.setMobileMenuFooter(false);
    document.body.classList.remove('fixed');
    document.body.classList.remove('footer');
    document.body.style.top = "0px";
    document.body.style.paddingRight = "0px";
    document.querySelector('#js-mobile_menu').classList.remove('footer_breadcrumbs');
    document.querySelector('#js-mobile_menu').classList.remove('footer');
    document.querySelector('#js-mobile_menu').classList.remove('active');
    document.querySelector('#js-mobile_menu').style.opacity = null;
    document.querySelector('#js-mobile_menu').style.height = null;
  };

  const backToTop = () => {
    if (window.pageYOffset > 0) {
      window.scrollBy(0, -80);
      setTimeout(backToTop, 0);
    }
  };

  useEffect(()=>{
    document.addEventListener('scroll', function () {
      setBottom(window.scrollY + window.innerHeight > document.body.offsetHeight - document.querySelector('.footer').offsetHeight
        ? ((window.scrollY + window.innerHeight) - (document.body.offsetHeight - document.querySelector('.footer').offsetHeight)) + 44
        : 44);
      setDisplay((window.innerHeight * 2) > window.scrollY + window.innerHeight ? 'none' : 'flex')
    });
  }, [size]);

  return (
    <footer className={`footer ${(location.pathname === INDEX_ROUTE || location.pathname === INDEX_ROUTE+'uk/') ? 'footer_breadcrumbs' : ''}`} id="js-footer">
      <div className="center" style={{paddingRight: `${device.desktop() && paddingRight}px`}}>
        <div className="center_content">
          <div className="left_block">
            <NavLink
              to={GenerateURL(INDEX_ROUTE)}
              className="logo"
              itemProp="logo"
              onClick={CloseMenu}
            >
              <img src={ico_logo} alt="logotype" title="logotype" />
            </NavLink>
          </div>
          <div className="right_block">
            <button className={`js-mobile_click mobile_click ${props.mobileMenu && 'active'}`} onClick={OpenMenu}>
              <img className="open" src={ico_menu_open} alt="click" />
              <img className="close" src={ico_menu_close} alt="close" />
            </button>
          </div>
        </div>
      </div>
      {props?.dataGaming && <div className="top" style={{paddingRight: `${topPaddingRight}px`}}>
          {props?.dataGaming?.browser_game && <a className="browser" href={props?.dataGaming?.browser_game} rel='nofollow'>
            <div>
              <img src={ico_browser} alt={t('Играть в браузере')} title={t('Играть в браузере')} />
              <span>{t('играть в браузере')}</span>
            </div>
          </a>}
          {platform?.os?.family && (platform?.os?.family === 'Android') && props?.dataGaming?.android_app && <a className="android" href={props?.dataGaming?.android_app}>
            <div>
              <img src={ico_android} alt={t('Скачать приложение')} title={t('Скачать приложение')} />
              <span>{t('скачать приложение')}</span>
            </div>
          </a>}
          {platform?.os?.family && (platform?.os?.family?.indexOf('Windows') !== -1) && props?.dataGaming?.windows_app && <a className="android" href={props?.dataGaming?.windows_app}>
            <div>
              <img src={ico_windows} alt={t('Скачать приложение')} title={t('Скачать приложение')} />
              <span>{t('скачать приложение')}</span>
            </div>
          </a>}
          {platform?.os?.family && (platform?.os?.family?.indexOf('iOS') !== -1) && props?.dataGaming?.ios_app && <a className="android" href={props?.dataGaming?.ios_app}>
            <div>
              <img src={ico_ios} alt={t('Скачать приложение')} title={t('Скачать приложение')} />
              <span>{t('скачать приложение')}</span>
            </div>
          </a>}
        </div>}
      <div className="bottom" style={{paddingRight: `${device.desktop() && paddingRight}px`}}>
        {(location.pathname === INDEX_ROUTE || location.pathname === INDEX_ROUTE+'uk/') && <div className="breadcrumbs">
          <ul className="breadcrumbs_box" itemScope="" itemType="http://schema.org/BreadcrumbList">
            <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
              <NavLink to={INDEX_ROUTE} itemProp="item">
                <h1 itemProp="name">СhampionСlub</h1></NavLink>
              <meta itemProp="position" content="1" />
            </li>
            <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
              <a href={`${INDEX_ROUTE}#number-one`} itemProp="item">
                <span itemProp="name">{t('Клуб Чемпионов')} — №❶</span></a>
              <meta itemProp="position" content="2" />
            </li>
            <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
              <a href={`${INDEX_ROUTE}#best`} itemProp="item">
                <span itemProp="name">{t('Присоединяйся')}!</span></a>
              <meta itemProp="position" content="3" />
            </li>
          </ul>
        </div>}
        <span>{new Date().getFullYear()} © ChampionClub. {t('Все права защищены')}.</span>
      </div>
      {!device.mobile() && <div className="back_to_top" onClick={backToTop} style={{bottom: `${device.desktop() && bottom}px`, display: display}}>
        <span>{t('В начало')}</span>
      </div>}
    </footer>
  );
};

export default observer(Footer);