import React from 'react';
import {Switch, Route} from 'react-router-dom'
import {publicRoutes} from "../routes";
import {default_language, languages} from "../utils/lang";
import ErrorPage404 from "../pages/ErrorPage404";
import {observer} from "mobx-react-lite";

const AppRouter = observer(() => {
  return (
    <Switch>
      {publicRoutes.map(({name, path, Component, FormSelect}) =>
        <Route
          key={path}
          path={path}
          component={match => <Component name={name} FormSelect={FormSelect} match={match}/>}
          exact
        />
      )}
      {publicRoutes.map(({name, path, Component, FormSelect}) =>
        languages.map(({code}) =>
          default_language !== code && <Route
            key={`/${code}${path}`}
            path={`/${code}${path}`}
            component={match => <Component name={name} FormSelect={FormSelect} match={match}/>}
            exact
          />
        )
      )}
      <Route path='*' component={ErrorPage404} status={404} />
    </Switch>
  );
});

export default AppRouter;