import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import {default_language, languages} from "./utils/lang";

let allLang = [];
languages.map(({code}) => allLang.push(code));

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: allLang,
    fallbackLng: default_language,
    detection: {
      order: ['path'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/static/champion_club_api/assets/locales/{{lng}}/translation.json?v=2'
    }
  });

export default i18n;