import React from 'react';
import {Link} from 'react-router-dom';
import {VIDEO_ROUTE} from "../../utils/consts";
import i18next from "i18next";
import {default_language} from "../../utils/lang";
import {observer} from "mobx-react-lite";

const SubCategoryBox = ({item}) => {

  return (
    <>
      <Link
        className="sub_category_box"
        onClick={()=>i18next.changeLanguage(item.lang)}
        to={`${item.lang === default_language ? `` : `/${item.lang}`}${VIDEO_ROUTE}/${item?.url_params?.category_slug}/${item?.url_params?.video_slug}`}
      >
        <div className="img">
          <img src={item.preview_image} alt={item?.name} title={item?.name}/>
        </div>
        <div className="title">
          <h3>{item.name}</h3>
        </div>
      </Link>
    </>
  );
};

export default observer(SubCategoryBox);