import PathToRegexp from "path-to-regexp";
import {ROUTE} from "./consts";
import {default_language} from "./lang";

const getLanguagePath = () => {
  const routeComponents = PathToRegexp(ROUTE).exec(window.location.pathname);
  if (routeComponents && routeComponents[2]) {
    if (routeComponents && routeComponents[1].length === 2) {
      return routeComponents[1];
    } else {
      return default_language;
    }
  } else if (routeComponents && routeComponents[1]) {
    if (routeComponents && routeComponents[1].length === 2) {
      return routeComponents[1];
    } else {
      return default_language;
    }
  } else {
    if (routeComponents && routeComponents[1].length === 2) {
      return routeComponents[1];
    } else {
      return default_language;
    }
  }
};


export default getLanguagePath;