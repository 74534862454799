export const API_URL = `/api`;

export const ROUTE = "/:locale/:path*";

export const API_SEO = '/seo';
export const API_RADIO = '/radio';
export const API_SOCIAL = '/social';
export const API_GAMING = '/gaming/url';
export const API_INDEX_VIDEO = '/index/video';
export const API_INDEX_TOURNAMENTS = '/index/tournaments';
export const API_INDEX_ANNOUNCEMENT = '/announcement';
export const API_ARCHIVE = '/archive';
export const API_WINNERS = '/tournaments';
export const API_BLOG = '/blog';
export const API_BLOG_DETAILS = '/blog/detail';
export const API_ACTIONS = '/sale';
export const API_SALE_DETAILS = '/sale/detail';
export const API_VIDEO = '/video';
export const API_VIDEO_SLIDER = '/video/slider';
export const API_VIDEO_CATEGORY = '/video/category';
export const API_VIDEO_DETAIL = '/video/detail';
export const API_VIDEO_CATEGORY_LIST = '/video/category/video_list';
export const API_VIDEO_CATEGORY_DETAILS = '/video/category/detail';

export const INDEX_ROUTE = '/';
export const ANNOUNCEMENT_ROUTE = '/announcement';
export const ANNOUNCEMENT_ARCHIVE_ROUTE = '/announcement/archive';
export const WINNERS_ROUTE = '/winners';
export const BLOG_ROUTE = '/blog';
export const BLOG_DETAILS_ROUTE = '/blog/:id';
export const ACTIONS_ROUTE = '/actions';
export const ACTIONS_DETAILS_ROUTE = '/actions/:id';
export const VIDEO_ROUTE = '/video';
export const VIDEO_CATEGORY_ROUTE = '/video/:id';
export const VIDEO_CATEGORY_DETAILS_ROUTE = '/video/:id/:id';
