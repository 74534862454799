import React from 'react';
import {observer} from "mobx-react-lite";

const Icons = props => {

  return (
    <>
      {props?.dataSocial && <div className="icons">
        {props?.dataSocial.map(item => (<a className="icon" data-alt={item?.social_url_type} href={item?.url}>
          <img src={item?.logotype} alt={item?.social_url_type} />
        </a>))}
      </div>}
    </>
  );
};

export default observer(Icons);