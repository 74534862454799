import React, { createContext, useEffect, useState } from 'react';
import {BrowserRouter} from 'react-router-dom'
import AppRouter from "./components/AppRouter";
import ScrollToTop from "./utils/ScrollToTop";
import Header from "./components/Header";
import MobileMenu from "./components/MobileMenu";
import Footer from "./components/Footer";
import {useFetching} from "./hooks/useFetching";
import ApiSocial from "./services/Social";
import {API_GAMING, API_SOCIAL} from "./utils/consts";
import ApiGaming from "./services/Gaming";
import i18next from "i18next";
import getLanguagePath from "./utils/getLanguagePath";

export const LanguageContext = createContext();

function App() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileMenuFooter, setMobileMenuFooter] = useState(false);
  const [dataSocial, setDataSocial] = useState();
  const [dataGaming, setDataGaming] = useState();
  const [languageContext, setLanguageContext] = useState({});


  useEffect(() => {
    function screenHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    screenHeight();
    window.addEventListener('resize', function () {
      setTimeout(function () {
        screenHeight();
      }, 400);
    });
  });

  const [FunctionApiSocial] = useFetching(async () => {
    const response = await ApiSocial.getApiSocial(API_SOCIAL);
    setDataSocial(response.data?.social);
  });

  const [FunctionApiGaming] = useFetching(async () => {
    const response = await ApiGaming.getApiGaming(API_GAMING);
    setDataGaming(response.data);
  });

  useEffect(()=>{
    FunctionApiSocial();
    FunctionApiGaming();
  }, [i18next.language]);

  useEffect(()=>{
    i18next.changeLanguage(getLanguagePath());
  });

  return (
    <LanguageContext.Provider value={[languageContext, setLanguageContext]}>
      <BrowserRouter>
        <MobileMenu
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
          mobileMenuFooter={mobileMenuFooter}
          setMobileMenuFooter={setMobileMenuFooter}
          dataSocial={dataSocial}
        />
        <Header
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
          dataSocial={dataSocial}
          setMobileMenuFooter={setMobileMenuFooter}
          dataGaming={dataGaming}
        />
        <ScrollToTop />
        <AppRouter />
        <Footer
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
          mobileMenuFooter={mobileMenuFooter}
          setMobileMenuFooter={setMobileMenuFooter}
          dataGaming={dataGaming}
        />
      </BrowserRouter>
    </LanguageContext.Provider>
  );
}

export default App;
