import React from 'react';
import {useTranslation} from "react-i18next";

const NoResult = () => {
  const { t } = useTranslation();

  return (
    <div className="no_result"><span>{t('Ничего не найдено')}</span></div>
  );
};

export default NoResult;