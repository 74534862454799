import React, { useState, useEffect } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import TournamentsTr from "./TournamentsTr";

import 'overlayscrollbars/css/OverlayScrollbars.css';
import {useFetching} from "../../hooks/useFetching";
import ApiIndexTournaments from "../../services/IndexTournaments";
import {API_INDEX_TOURNAMENTS} from "../../utils/consts";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";


const Tournaments = () => {
  const {t} = useTranslation();
  const [data, setData] = useState();

  const [FunctionApiIndexTournaments, loadingFunctionApiIndexTournaments] = useFetching(async (period) => {
    const response = await ApiIndexTournaments.getApiIndexTournaments(API_INDEX_TOURNAMENTS, period);
    setData(response.data);
  });

  const toggleTab = (e) => {
    const button = e.target.closest('button');
    const tab = e.target.closest('.tab');
    const active = tab.querySelector('button.active');
    if (active) {
      active.classList.remove('active');
      active.disabled = false;
    }
    button.classList.add('active');
    button.disabled = true;
    FunctionApiIndexTournaments(button.dataset.period);
  };

  useEffect(()=>{
    const tab = document.querySelector('.tab');
    if (!tab.querySelector('button.active')) {
      tab.querySelector('button').classList.add('active');
      tab.querySelector('button').disabled = true;
    }
    FunctionApiIndexTournaments(tab.querySelector('button.active').dataset.period);
  }, []);

  return (
    <div className="the_result_of_tournaments">
      <h1>{t('Лидеры турниров')}</h1>
      <div className="tab" id="period">
        <ul>
          {/*<li>*/}
          {/*  <button*/}
          {/*    onClick={toggleTab}*/}
          {/*    data-period="days"*/}
          {/*  >{t('Дневной')}</button>*/}
          {/*</li>*/}
          <li>
            <button
              onClick={toggleTab}
              data-period="weeks"
            >{t('Недельный')}</button>
          </li>
          {/*<li>*/}
          {/*  <button*/}
          {/*    onClick={toggleTab}*/}
          {/*    data-period="month"*/}
          {/*  >{t('Месячный')}</button>*/}
          {/*</li>*/}
        </ul>
      </div>
        <div className="table">
          <OverlayScrollbarsComponent>
          <div className="table_responsive">
            <table>
              <thead>
              <tr>
                <th>
                  <span>{t('Логины лидеров турниров в реальном времени')}</span>
                </th>
                <th />
                <th />
              </tr>
              </thead>
              <tbody>
              {[...Array(10)].map((item, i) => <TournamentsTr key={i} loading={loadingFunctionApiIndexTournaments} data={data?.winner_list_matrix[i]} />)}
              </tbody>
            </table>
          </div>
          </OverlayScrollbarsComponent>
        </div>
    </div>
  );
};

export default observer(Tournaments);