import React, {useState, useEffect, useContext} from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import ApiSeo from "../../services/Seo";
import {API_SEO} from "../../utils/consts";
import i18next from "i18next";
import {observer} from "mobx-react-lite";
import generateLanguageURL from "../../utils/generateLanguageURL";
import {LanguageContext} from "../../App";

const Seo = ({page}) => {
  const location = useLocation();
  const [seo, setSeo] = useState();
  const [languageContext, setLanguageContext] = useContext(LanguageContext);

  const [FunctionApiSeo, isLoading] = useFetching(async () => {
    const response = await ApiSeo.getApiSeo(API_SEO, page);
    setSeo(response.data);
  });

  useEffect(() => {
    FunctionApiSeo();
  }, []);

  useEffect(()=>{
    setLanguageContext({
      'uk': location.pathname,
      'ru': location.pathname
    });
  }, [!isLoading && seo]);

  return (
    <Helmet>
      <html lang={i18next.language} />
      <title>{seo?.seo_title}</title>
      <meta name="description" content={seo?.seo_description} />
      <meta property="og:description" content={seo?.seo_description} />
      <link rel="alternate" hrefLang="uk-ua" href={window.location.origin.toString()+('/'+generateLanguageURL(location.pathname, "uk")).replace('//', '/')} />
      <link rel="alternate" hrefLang="ru-ua" href={window.location.origin.toString()+('/'+generateLanguageURL(location.pathname, "ru")).replace('//', '/')} />
      <link rel="alternate" hrefLang="x-default" href={window.location.origin.toString()+('/'+generateLanguageURL(location.pathname, "ru")).replace('//', '/')} />
      {page === 'video' && <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
  );
};

export default observer(Seo);