import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ACTIONS_ROUTE, API_SALE_DETAILS, INDEX_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";
import GenerateURL from "../utils/generateURL";
import {useFetching} from "../hooks/useFetching";
import ApiActionsDetails from "../services/ActionsDetails";
import Sidebar from "../components/SideBar";
import Share from "../components/Share";
import parse from "html-react-parser";
import i18next from "i18next";
import generateLanguageURL from "../utils/generateLanguageURL";
import {Helmet} from "react-helmet";
import ErrorPage404 from "./ErrorPage404";
import Loading from "../components/Loading";
import {observer} from "mobx-react-lite";
import {LanguageContext} from "../App";

import '../style/partials/_details.scss'
import Breadcrumbs from "../components/Breadcrumbs";

const ActionsDetailsPage = () => {
  const {t} = useTranslation();
  const [item, setItem] = useState();
  const params = useParams();
  const [languageContext, setLanguageContext] = useContext(LanguageContext);

  const [FunctionApiActionsDetails, isLoading, errorMessage, errorStatus, errorPage] = useFetching(async (id) => {
    const response = await ApiActionsDetails.getApiActionsDetails(API_SALE_DETAILS, id);
    setItem(response.data);
  });

  useEffect(() => {
    FunctionApiActionsDetails(params.id);
  }, [params.id]);

  useEffect(()=>{
    setLanguageContext({
      'uk': item?.seo_urls?.uk_url ? `${ACTIONS_ROUTE}/${item?.seo_urls?.uk_url}` : '',
      'ru': item?.seo_urls?.ru_url ? `${ACTIONS_ROUTE}/${item?.seo_urls?.ru_url}` : ''
    });
  }, [!isLoading && item]);

  const Time = (date) => {
    const options = {month: 'long', day: 'numeric', year: "numeric"};
    return new Date(date).toLocaleDateString(i18next.language, options);
  };

  return errorPage && !isLoading ? <ErrorPage404 status={errorStatus} message={errorMessage} /> : isLoading ? <Loading /> :  (
    <>
      {item && <Helmet>
        <html lang={i18next.language} />
        <title>{item?.seo_title}</title>
        <meta name="description" content={item?.seo_description} />
        <meta property="og:description" content={item["og:description"]} />
        <link rel="alternate" hrefLang="uk-ua" href={item?.seo_urls?.uk_url ? window.location.origin.toString()+('/'+generateLanguageURL(ACTIONS_ROUTE+'/'+item.seo_urls.uk_url, "uk")).replace('//', '/') : ''} />
        <link rel="alternate" hrefLang="ru-ua" href={item?.seo_urls?.ru_url ? window.location.origin.toString()+('/'+generateLanguageURL(ACTIONS_ROUTE+'/'+item.seo_urls.ru_url, "ru")).replace('//', '/') : ''} />
        <link rel="alternate" hrefLang="x-default" href={item?.seo_urls?.def_url ? window.location.origin.toString()+('/'+generateLanguageURL(ACTIONS_ROUTE+'/'+item.seo_urls.def_url, "ru")).replace('//', '/') : ''} />
      </Helmet>}
      {item && <div className="details blog container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content={item.rating} />
          <meta property="ratingCount" content={item.voices} />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: ACTIONS_ROUTE, name: 'Акции'},
          {url: ACTIONS_ROUTE+'/'+params.id, name: item?.name}
        ]}/>
        <div className="container">
          <div className="content">
            <div className="content_box" itemScope="" itemType="http://schema.org/Article">
              <div className="top">
                <div className="left_block">
                  <h3>{t('Блог')}</h3>
                  <div className="title">
                    <h1 itemProp="name">{item?.name}</h1>
                  </div>
                  <div className="social">
                    <link itemProp="url" href={window.location.origin.toString()+(GenerateURL(ACTIONS_ROUTE+'/'+params.id))} />
                    <meta itemProp="datePublished" content={item?.publication_date} />
                    <meta itemProp="headline" content={item?.name} />
                    {item?.publication_date && <span>{Time(item?.publication_date)}</span>}
                    {item?.author && <span className="author" itemProp="author">{item?.author}</span>}
                    {item?.views && <span className="author">{t('просмотров')}: {item?.views}</span>}
                    <Share url={window.location.origin.toString()+(GenerateURL(ACTIONS_ROUTE+'/'+params.id))} />
                  </div>
                </div>
                <div className="right_block">
                  <div className="img">
                    <img itemProp="image" src={item?.preview_image} alt={item?.name} title={item?.name} />
                  </div>
                </div>
              </div>
              <div className="editor content_text" itemProp="articleBody">
                {item?.text && parse(item?.text)}
              </div>
              <div className="bottom">
                <div className="social">
                  {item?.publication_date && <span>{Time(item?.publication_date)}</span>}
                  <Share url={window.location.origin.toString()+(GenerateURL(ACTIONS_ROUTE+'/'+params.id))} />
                </div>
              </div>
            </div>
            {item?.recommendation && <Sidebar items={item?.recommendation} page={ACTIONS_ROUTE} />}
          </div>
        </div>
      </div>}
    </>
  );
};

export default observer(ActionsDetailsPage);