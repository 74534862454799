import React from 'react';
import {observer} from "mobx-react-lite";

const Loader = () => {
  return (
    <div id="preloader">
      <div className="spinner">
          <div className="cube1" />
          <div className="cube2" />
      </div>
    </div>
  );
};

export default observer(Loader);