import axios from 'axios'

import {API_URL} from "../utils/consts";
import getLanguagePath from "../utils/getLanguagePath";
import {default_language} from "../utils/lang";

const api = axios.create({
  withCredentials: true,
  baseURL: API_URL
});

api.interceptors.request.use(config => {
  const currentLanguageCode = getLanguagePath() || default_language;

  config.params = {
    lang: currentLanguageCode,
    ...config.params,
  };
  return config;
});

export default api