import React, {useState, useEffect, useRef} from 'react';
import Loading from "../Loading";
import {useTranslation} from "react-i18next";
import NoResult from "../NoResult";
import device from "current-device";
import {useFetching} from "../../hooks/useFetching";
import {API_VIDEO} from "../../utils/consts";
import PostService from "../../API/PostService";
import SubCategoryBox from "./SubCategoryBox";
import {observer} from "mobx-react-lite";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

const SubCategory = ({name, name_mobile, name_not_mobile, category_name}) => {
  const { t } = useTranslation();

  const [posts, setPosts] = useState(
    [
    //   {
    //     "id": 58,
    //     "name": "Тест 14.03",
    //     "preview_image": "/media/media/VideoPreview/041-741x367_8OZWkNJ.jpg",
    //     "url_params": {
    //       "category_slug": "chempionskaia-tusovka",
    //       "video_slug": "test-14-03"
    //     }
    //   },
    //   {
    //     "id": 57,
    //     "name": "Первый стрим",
    //     "preview_image": "/media/media/VideoPreview/5adf1b97742a65d0a3c98299c545570b_BnP064W.jpg",
    //     "url_params": {
    //       "category_slug": "strimery",
    //       "video_slug": "pervyi-strim"
    //     }
    //   },
    //   {
    //     "id": 54,
    //     "name": "Лидеры выигрышей",
    //     "preview_image": "/media/media/VideoPreview/pic-00892_jRbMURi.jpg",
    //     "url_params": {
    //       "category_slug": "chempionskii-chas",
    //       "video_slug": "lidery-vyigryshei"
    //     }
    //   },
    //   {
    //     "id": 51,
    //     "name": "Тусовка №2",
    //     "preview_image": "/media/media/VideoPreview/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9_Tadbf09.png",
    //     "url_params": {
    //       "category_slug": "chempionskaia-tusovka",
    //       "video_slug": "tusovka-2"
    //     }
    //   },
    //   {
    //     "id": 52,
    //     "name": "Наша тусовка. Лучшие моменты",
    //     "preview_image": "/media/media/VideoPreview/041-741x367_qds8kZJ.jpg",
    //     "url_params": {
    //       "category_slug": "chempionskaia-tusovka",
    //       "video_slug": "nasha-tusovka-luchshie-momenty"
    //     }
    //   }
    ]
  );
  const [post, setPost] = useState(0);
  const [page, setPage] = useState(0);
  const instance = useRef(null);

  const [fetchPosts, isPostsLoading] = useFetching(async (page) => {
    const response = await PostService.getAll(`${API_VIDEO}?category_name=${category_name}`, page);
    setPosts([...posts, ...response.data?.videos]);
    setPost(response.data?.videos.length);
  });

  const handleClick = () => {
    if (post && post >= 5) {
      setPage(page + 1)
    }
  };

  useEffect(() => {
    if (!isPostsLoading) {
      fetchPosts(page);
    }
  }, [page]);


  return (
    <>
      <div className="sub_category">
        {name && name}
        {device.mobile() && name_mobile && name_mobile}
        {!device.mobile() && name_not_mobile && name_not_mobile}
        {device.desktop()
          ? <div className="sub_category_boxing">
              {posts && posts.length > 0 && posts.map(item => <SubCategoryBox key={item.id} item={item}/>)}
              <div className="sub_category_box none" />
              <div className="sub_category_box none" />
              <div className={`button ${post && post >= 5 ? 'active' : ''}`}>
                <button className="js-show_more" type="button" onClick={handleClick}>
                  <span>{t('Показать больше')}</span></button>
              </div>
              {isPostsLoading
                ? <Loading />
                : !posts.length && <NoResult />
              }
            </div>
          : <>
              <OverlayScrollbarsComponent
                ref={instance}
                options={{
                  callbacks: {
                    onScroll: function () {
                      if (!device.desktop()) {
                        if (instance.current.osInstance().scroll().position.x >= (instance.current.osInstance().scroll().max.x - window.innerWidth)) {
                          if (post && post >= 5) {
                            setPage(page + 1)
                          }
                        }
                      }
                    }
                  }
                }}
              >
                <div className="sub_category_boxing">
                  {posts && posts.length > 0 && posts.map(item => <SubCategoryBox key={item.id} item={item}/>)}
                  <div className="sub_category_box none" />
                  <div className="sub_category_box none" />
                </div>
              </OverlayScrollbarsComponent>
              {!isPostsLoading && !posts.length && <NoResult />}
            </>
        }
      </div>
    </>
  );
};

export default observer(SubCategory);