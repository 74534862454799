import React, {useEffect, useRef, useState} from 'react';
import {API_BLOG, BLOG_ROUTE, INDEX_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";
import Box from "../components/Blog/box";
import Loading from "../components/Loading";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import {useObserver} from "../hooks/useObserver";
import Seo from "../components/Seo";
import {observer} from "mobx-react-lite";
import NoResult from "../components/NoResult";

import '../style/partials/_blog.scss'
import Breadcrumbs from "../components/Breadcrumbs";

const BlogPage = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState(
    [
  //   {
  //     "name": "Що таке Lorem Ipsum?",
  //     "title": "Lorem Ipsum - це текст-\"риба\", що використовується в друкарстві та дизайні.",
  //     "preview_image": "/media/media/PostPreview/79404197.jpg",
  //     "slug": "shcho-take-lorem-ipsum",
  //     "lang": "uk"
  //   },
  //   {
  //     "name": "О нас",
  //     "title": "Любители азартных игр даже после изменения законодательства об игорном бизнесе имеют возможность увлекательно и интересно проводить досуг на просторах интернета",
  //     "preview_image": "/media/media/PostPreview/30.jpg",
  //     "slug": "o-nas",
  //     "lang": "ru"
  //   },
  //   {
  //     "name": "Лучший выбор онлайн игр на украинском рынке азартных игр",
  //     "title": "Благодаря принятому закону об игорном бизнесе Украина стала привлекательной платформой для поклонников азартных игр. Современный гемблинг предлагает огромное",
  //     "preview_image": "/media/media/PostPreview/29.jpg",
  //     "slug": "luchshii-vybor-onlain-igr-na-ukrainskom-rynke-azartnykh-igr",
  //     "lang": "ru"
  //   },
  //   {
  //     "name": "ChampionCasino переехало в онлайн",
  //     "title": "Изменения, произошедшие в украинском законодательстве, которые коснулись игорного бизнеса, привели к закрытию наземных казино",
  //     "preview_image": "/media/media/PostPreview/10.jpg",
  //     "slug": "championcasino-pereekhalo-v-onlain",
  //     "lang": "ru"
  //   },
  //   {
  //     "name": "Как сорвать Джекпот в игровых автоматах онлайн казино",
  //     "title": "Джекпот – это крупный выигрыш, который есть практически в любом онлайн слоте. Цель каждого игрока заключается в получении Джекпота",
  //     "preview_image": "/media/media/PostPreview/27.jpg",
  //     "slug": "kak-sorvat-dzhekpot-v-igrovykh-avtomatakh-onlain-kazino",
  //     "lang": "ru"
  //   },
  //   {
  //     "name": "Секреты игровых автоматов в онлайн казино",
  //     "title": "С момента появления первого игрового автомата вопрос, как получить выигрыш, волнует всех игроков. Какие же существуют секреты аппаратов",
  //     "preview_image": "/media/media/PostPreview/p1.png",
  //     "slug": "sekrety-igrovykh-avtomatov-v-onlain-kazino",
  //     "lang": "ru"
  //   }
  ]
  );
  const [post, setPost] = useState();
  // const [totalPages, setTotalPages] = useState(0);
  // const [limit] = useState(6);
  const [page, setPage] = useState(0);
  const lastElement = useRef();

  const [fetchPosts, isPostsLoading] = useFetching(async (page) => {
    const response = await PostService.getAll(API_BLOG, page);
    setPosts([...posts, ...response.data?.blog_list]);
    setPost(response.data?.blog_list.length);
    // const totalCount = response.headers['x-total-count'];
    // setTotalPages(getPageCount(totalCount, limit));
  });

  useObserver(lastElement, post > 0, isPostsLoading, () => {
    setPage(page + 1)
  });

  useEffect(() => {
    fetchPosts(page);
  }, [page]);

  return (
    <>
      <Seo page="blog" />
      <div className="blog container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content="4.6" />
          <meta property="ratingCount" content="1743" />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: BLOG_ROUTE, name: 'Блог'}
        ]}/>
        <div className="container">
          <div className="title">
            <h1>{t('Блог')}</h1>
          </div>
          <div className="boxing" id="Boxing">
            {posts.length > 0 && posts.map(item => <Box key={item.id} item={item}/>)}
          </div>
          <div ref={lastElement} className="lastElement" />
          {isPostsLoading
            ? <Loading />
            : !posts.length && <NoResult />
          }
        </div>
      </div>
    </>
  );
};

export default observer(BlogPage);