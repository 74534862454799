import { useState } from 'react'

export const useFetching = (callback) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);
  const [errorPage, setErrorPage] = useState(false);

  const fetching = async (...args) => {
    try {
      setErrorPage(false);
      setIsLoading(true);
      await callback(...args)
    } catch (error) {
      if (error) {
        const status = error?.response?.status;
        if (status) {
          setErrorStatus(status);
          setErrorPage(true)
        }
      }
      setIsLoading(false);
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  };

  return [fetching, isLoading, errorMessage, errorStatus, errorPage]
};
