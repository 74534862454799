import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import i18next from "i18next";
import {default_language} from "../../utils/lang";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import device from "current-device";

const SideBar = ({items, page}) => {
  const {t} = useTranslation();

  return (
    <div className="sub_category">
      <h2>{t('Рекомендуем тебе')}</h2>
        {device.desktop()
          ? <div className="sub_category_boxing">
              {items && items.map(item=><Link
                key={item.id}
                className="sub_category_box"
                onClick={()=>i18next.changeLanguage(item.lang)}
                to={`${item?.lang === default_language ? `` : `/${item?.lang}`}${page}/${item?.url?.category_slug}/${item?.url?.video_slug}`}>
                <div className="img"><img src={item.preview_image} alt={`${item.name} ChampionClub`} />
                </div>
                <div className="title"><h3>{item.name}</h3></div>
              </Link>)}
            </div>
          : <OverlayScrollbarsComponent>
              <div className="sub_category_boxing">
                {items && items.map(item=> <Link
                  key={item.id}
                  className="sub_category_box"
                  onClick={()=>i18next.changeLanguage(item.lang)}
                  to={`${item?.lang === default_language ? `` : `/${item?.lang}`}${page}/${item?.url?.category_slug}/${item?.url?.video_slug}`}>
                  <div className="img"><img src={item.preview_image} alt={`${item.name} ChampionClub`} />
                  </div>
                  <div className="title"><h3>{item.name}</h3></div>
                </Link>)}
              </div>
            </OverlayScrollbarsComponent>
        }
    </div>
  );
};

export default observer(SideBar);