import React from 'react';
import Seo from "../components/Seo";
import {INDEX_ROUTE, VIDEO_ROUTE} from "../utils/consts";
import {useTranslation} from "react-i18next";
import SubCategory from "../components/Video/SubCategory";
import Slider from "../components/Video/Slider";
import Category from "../components/Video/Category";

import '../style/partials/_video.scss'
import Breadcrumbs from "../components/Breadcrumbs";

const VideoPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo page="video" />
      <div className="video container-fluid">
        <div vocab="https://schema.org/" typeof="AggregateRating">
          <div property="itemReviewed" typeof="Organization">
            <meta property="name" content="ChampionClub" />
          </div>
          <meta property="bestRating" content="5" />
          <meta property="ratingValue" content='4.7' />
          <meta property="ratingCount" content='1076' />
        </div>
        <Breadcrumbs items={[
          {url: INDEX_ROUTE, name: 'Главная'},
          {url: VIDEO_ROUTE, name: 'Видео'}
        ]}/>
        <div className="container">
          <Slider />
          <SubCategory
            category_name=''
            name={<h2>{t('Рекомендуем тебе')}</h2>}
          />
          <Category />
          <SubCategory
            category_name='champion_party'
            name_mobile={<h2 className="mobile">{t('Чемпионская тусовка')}: <span className="blue">{t('Лучшие моменты')}</span></h2>}
            name_not_mobile={<h2 className="not_mobile"><span className="blue">{t('Лучшие моменты')}</span> {t('в категории')} «{t('Чемпионская тусовка')}»</h2>}
          />
          <SubCategory
            category_name='champion_time'
            name_mobile={<h2 className="mobile">{t('Чемпионский час')}: <span className="blue">{t('Топовые выигрыши')}</span></h2>}
            name_not_mobile={<h2 className="not_mobile"><span className="blue">{t('Топовые выигрыши')}</span> {t('в категории')} «{t('Чемпионский час')}»</h2>}
          />
          <SubCategory
            category_name='streams'
            name_mobile={<h2 className="mobile">{t('Стримеры')}: <span className="blue">{t('Топ заносы')}</span></h2>}
            name_not_mobile={<h2 className="not_mobile"><span className="blue">{t('Топ заносы')}</span> {t('в категории')} «{t('Стримеры')}»</h2>}
          />
        </div>
      </div>
    </>
  );
};

export default VideoPage;